import React, { useState, useEffect, Fragment } from 'react';
import Footer from './footer';
import Menu from './menu';
import StepProgressBar from 'react-step-progress';
import Paso1 from '../Formulario/paso1';
import Paso2 from '../Formulario/paso2';
import Paso4 from '../Formulario/paso4';
import Paso3 from '../Formulario/paso3';
import ResultadoSolicitud from '../Formulario/resultadoSolicitud';
import MensajeAdvertencia from '../../Services/MensajeAdvertencia';
import Bienvenida from '../Formulario/bienvenida';
import Menu2 from './menu2';
import { ReembolsoSevices } from '../../Services';
const SITE_KEY = "6LcnogsaAAAAAIRnBz6EHYHRy4cJDHi7C4f0h-qq";
const SECRET_KEY = "6LcnogsaAAAAAONK78K9cH-8NEkw18U_7wNTvPCV";

const LayoutFormulario = (props) => {
    const [ModoPrueba] = useState(false); // FLAG PARA PASAR CONSUMOS DE WS , MI INTERNET NO SIRVE 
    const step0Content = <Bienvenida></Bienvenida>;
    const step1Content = <Paso1></Paso1>;
    const step2Content = <Paso2></Paso2>;
    const step3Content = <Paso3></Paso3>;
    const step4Content = <Paso4></Paso4>;
    const step5Content = <ResultadoSolicitud />;
    const [Pasos] = useState(
        [
            {
                name: 'Paso 1',
                label: 'Validación',
                content: step0Content,
                validator: ''
            },
            {
                name: 'Paso 1',
                label: 'Validación',
                content: step1Content,
                validator: ValidarPaso1
            },
            {
                name: 'Paso 2',
                label: 'Boleto',
                content: step2Content,
                validator: ValidarPaso2
            },
            {
                name: 'Paso 3',
                label: 'Motivo',
                content: step3Content,
                validator: ValidarPaso3
            },
            {
                name: 'Paso 4',
                label: 'Documentos',
                content: step4Content,
                validator: ""
            },
            {
                name: 'Paso 5',
                label: 'Restultado',
                content: step5Content,
                validator: ""
            }
        ]);

    useEffect(() => {
        (async function anyNameFunction() {
            // document.getElementsByClassName('_3uApM ')[0].style.display = 'flex';
            ValidarRechapcha();
        })();
    }, []);
    async function ValidarRechapcha() {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                    Chequear(token)
                });
            });
        });
    }
    async function Chequear(params) {
        var parmetro = JSON.stringify(params)
        const peticion = await ReembolsoSevices.Recapchav(parmetro, parmetro);
        // alert(JSON.stringify(peticion))
        if (peticion.status == 200) {

            if (peticion.resp != "EXITO") {
                document.getElementsByClassName('_3uApM ')[0].style.display = 'none';
                MensajeAdvertencia("Error en reCAPTCHA", "No fué posible validar el reCAPTCHA, refresque el sitio e intente de nuevo", "warning", "Ok")
                return;
            } else {
                // document.getElementsByClassName('_3uApM ')[0].style.display = 'flex';
            }
        }
    }





    function ValidarPaso1() {
        if (!ModoPrueba) {
            var Paso1Validado = window.localStorage.getItem("Paso1");
            var PoseeBoleto = window.localStorage.getItem("PoseeBoleto");
            if (PoseeBoleto == "S") {
                return true;
            }
            else {
                if (Paso1Validado == btoa(false)) {
                    MensajeAdvertencia("Advertencia", "Debe llenar los campos obligatorios, para continuar.", "warning", "Reintentar");
                    return false;

                }
                else {
                    return true
                }
            }
        }
        else {
            return true;
        }
    }

    function ValidarPaso2() {
        if (!ModoPrueba) {
            if (navigator.onLine) {
                var Paso2Validado = window.localStorage.getItem("Paso2");
                if (Paso2Validado == btoa(false)) {
                    MensajeAdvertencia("Advertencia", "Debe llenar los campos obligatorios, para continuar.", "warning", "Reintentar");
                    return false;
                }
                else {
                   return true;
                }
            }
            else {
                MensajeAdvertencia("Sin internet", "Actualmente no posee internet", "warning", "Ok")
                return false;
            }
        }
        else {
            return true;
        }

    }

    function ValidarPaso3() {
        if (!ModoPrueba) {
            if (navigator.onLine) {
                var Paso2Validado = window.localStorage.getItem("Paso3");
                if (Paso2Validado == btoa(false)) {
                    MensajeAdvertencia("Advertencia", "Debe llenar los campos obligatorios, para continuar.", "warning", "Reintentar");
                    return false;
                }
                else {
                   
                        return true
                    

                }
            }
            else {



                MensajeAdvertencia("Sin internet", "Actualmente no posee internet", "warning", "Ok")
                return false;
            }
        }
        else {
            return true;
        }
    }

    function onFormSubmit() {
        window.location.href = "https://avior.com.ve";
    }

    return (


        <>
            <Menu2 />
            <div class="theme-page-section _pt-90" style={{ minHeight: "100vh" }}>
                <div class="container ">

                    <StepProgressBar
                        primaryBtnClass={"btn btn-primary  _bg-p"}
                        secondaryBtnClass={"btn btn-primary-inverse _c-dark btn-white hover-Oscuro"}
                        previousBtnName={"Anterior"}
                        nextBtnName={"Siguiente"}
                        startingStep={0}
                        progressClass={'_c-dark'}
                        stepClass={'_c-dark'}
                        onSubmit={onFormSubmit}
                        steps={Pasos}
                    />

                </div>
            </div>
            <Footer />
        </>

    );
}
export default LayoutFormulario;