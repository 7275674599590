import React, { useState, useEffect, Fragment } from 'react';
import RespuestaC from './../Consulta/respuesta';
import LoaderScreen from '../layout/LoaderScreen';
import { ReembolsoSevices } from '../../Services';
import MensajeAdvertencia from '../../Services/MensajeAdvertencia';

import Popover from 'react-tiny-popover';
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import Moment from 'moment';
import swal from 'sweetalert';
const Indice = (props) => {
    var hoy = new Date();
    var anoPasado = hoy.setMonth(hoy.getMonth() - 48);
    const [FechaBoleto, setFechaBoleto] = useState(Moment(new Date())); // VALOR FECHA DE SOLO IDA
    const [ActivarPopoverFechas, setActivarPopoverFechas] = useState(false);
    const [Boleto, setBoleto] = useState(""); //BOLETO DEL RECLAMO 
    const [LongitudMaxBoleto] = useState(10);
    const [MostrarRespuesta, setMostrarRespuesta] = useState(false);
    const [ActivarLoader, setActivarLoader] = useState(false);
    const [Respuesta, setRespuesta] = useState([]);

    function solonumeros(inputtxt) {
        try {
            var numbers = /^[0-9]+$/;
            if (inputtxt.match(numbers)) {
                return true;
            }
            else {
                return false;
            }
        } catch (ex) {
            console.log('error', ex)
            return false;
        }
    }
    function AsignarFecha(value) {
        setFechaBoleto(value);
        setActivarPopoverFechas(false);
    }
    function AsignarValorBoleto(params) {
        if (solonumeros(params)) {
            if (params.length < LongitudMaxBoleto) {
                setBoleto(params);
            }
        }
    }

    async function BuscarResultado() {

        if (Boleto !== "") {
            setActivarLoader(true);
            try {

                const peticion = await ReembolsoSevices.ObtenerEstatusReembolso(Boleto, Moment(FechaBoleto).format("MM/DD/YYYY"));
                // alert(JSON.stringify(peticion))
                if (peticion.status == 200) {
                    setActivarLoader(false);
                    // alert(peticion.resp.length )
                    if (peticion.resp.length > 0) {


                        window.scrollTo(0, document.body.scrollHeight);
                        setRespuesta(peticion.resp);
                        setMostrarRespuesta(true)
                    }
                    else {
                        setActivarLoader(false);
                        setMostrarRespuesta(false);
                        MensajeAdvertencia("Advertencia", "No fué posible encontrar el número de solicitud ingresado. intente nuevamente", "warning", "Reintentar");

                    }
                }

            } catch (error) {

            }

        } else {
            MensajeAdvertencia("Advertencia", "Debe ingresar número de solicitud y la fecha del mismo, que recibió en su correo electrónico.", "warning", "Reintentar");
        }
    }
    useEffect(() => {

        
        setBoleto("");
    }, [])



    return (
        <>
            <LoaderScreen props={ActivarLoader} mensaje={"Validando boleto"} />

            <div className=' container _pt-60 _pb-20' >
                <div className='row'>
                    <div className='col-md-12 _pb-20'>
                        <h2 style={{color:"#565657"}}>
                            Bienvenido a nuestra sección de reembolso<br />
                            <small>Conoce nuestra política y gestiona tu requerimiento</small>
                        </h2>

                    </div>

                    <div className='col-md-4'></div>
                    <div className='col-md-4'>
                        <h2 className="text-center _pb-15 _pt-20 _br-15 _c-w _bg-p" style={{ fontSize: "1.3em", fontWeight: "normal", borderRadius: 20 }}> ¿Qué necesita hacer?</h2>
                    </div>
                    <div className='col-md-4'></div>
                    <div className='col-md-12 _pb-50'>
                        <h3 className=" text-center" ><span style={{color:"#575756"}}>Estamos para asistirte</span></h3>
                    </div>
                    <div className='col-md-5'>
                        <div className=" _p-30 _bg-w _br-4 theme-search-area-vert" style={{ border: "1px solid #dadada", minHeight: "400px", borderRadius: 15 }}>
                            <div className="row " data-gutter="20">
                                <div class="col-md-12 ">
                                    <h4 className='_c-p'>VERIFICAR EL ESTADO DE MI REEMBOLSO</h4>
                                </div>
                                <div class="col-md-12 ">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Fecha de solicitud</h5>
                                    <div class="theme-payment-page-form-item form-group">
                                        <Popover
                                            containerClassName={"text-center"}
                                            containerStyle={{ borderRadius: '0px 0px 10px 10px', border: '1.2px solid #CD171A', background: "#f8f8f8", zIndex: "999" }}
                                            arrowStyle={{ background: "#f8f8f8" }}
                                            isOpen={ActivarPopoverFechas}
                                            position={'bottom'}
                                            disableReposition
                                            onClickOutside={() => setActivarPopoverFechas(false)}
                                            content={({ position, nudgedLeft, nudgedTop, targetRect, popoverRect }) => (
                                                <>
                                                    <DateRangePicker
                                                        minimumDate={anoPasado}
                                                        maximumDate={new Date()}
                                                        selectedLabel={'Fecha en la cual emitió la solicitud de reembolso'}
                                                        locale={Moment.locale('es')}
                                                        showLegend={true}
                                                        firstOfWeek={1}
                                                        numberOfCalendars={1}
                                                        value={FechaBoleto}
                                                        onSelect={(ev) => AsignarFecha(ev)}
                                                        singleDateRange={false}
                                                        selectionType={'single'}
                                                    />
                                                </>
                                            )}
                                        >
                                            <input class="form-control seleccionable"
                                                onClick={() => setActivarPopoverFechas(!ActivarPopoverFechas)}
                                                type="text"
                                                value={Moment(FechaBoleto).format("YYYY/MM/DD")}
                                                placeholder="DD/MM/AAAA" />

                                        </Popover>

                                    </div>
                                </div>
                                <div class="col-md-12 _pt-20">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  N° de solicitud</h5>
                                    <div class="theme-payment-page-form-item form-group">
                                        <input
                                            onChange={(ev) => AsignarValorBoleto(ev.target.value)}
                                            value={Boleto}
                                            class="form-control"
                                            type="text"
                                            placeholder="N° de solicitud"
                                            data-provide="typeahead" />

                                    </div>
                                </div>
                                <div className="col-md-12 _pt-30">
                                    <a href="#respuesta" style={{ lineHeight: "37px" }} onClick={() => BuscarResultado()} class="seleccionable theme-search-area-submit _mt-0 theme-search-area-submit-curved theme-search-area-submit-curved2">
                                        <span class=" _c-w">Consultar</span>
                                    </a>
                                </div>
                                {/* <div className="col-md-12 _pt-20 text-left">
                                        <p><a className=" seleccionable _c-dark hover-Oscuro " href={'/solicitar'}>Solicitar un reembolso</a></p>
                                        <p className="text-center _pt-10">
                                            <hr></hr>
                                            <small>Antes de solicitar un reembolso, revisa las <a className="seleccionable _c-dark hover-Oscuro" style={{ textDecoration: "underline" }} target={'_Blank'} href="https://www.avior.com.ve/tarifas" rel="noopener noreferrer nofollow"><br />Condiciones de nuestras tarifas</a>.  </small>
                                        </p>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-1'></div>
                    <div className='col-md-5'>
                        <div className=" _p-30 _bg-w _br-4 theme-search-area-vert" style={{ border: "1px solid #dadada", minHeight: "400px", borderRadius: 15 }}>
                            <div className="row " data-gutter="20">
                                <div class="col-md-12 ">
                                    <h4 className='_c-p'>SOLICITAR UN REEMBOLSO</h4>
                                </div>
                                <div class="col-md-12 ">
                                    <ul >
                                        <li><b>Genere su requerimiento por separado</b>, de acuerdo a la cantidad de boletos.</li>
                                        <li>Al completar los datos solicitados, <b>recibirá vía correo electrónico el número de identificación</b> de su caso.</li>
                                        <li>Al validar, nos pondremos en contacto con usted.</li>

                                    </ul>
                                </div>

                                <div className="col-md-12 _pt-40">
                                    <br />
                                    <a style={{ lineHeight: "37px" }} href={'/solicitar'} class="seleccionable theme-search-area-submit _mt-0 theme-search-area-submit-curved theme-search-area-submit-curved2">
                                        <span class=" _c-w">Solicitar</span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>

                        <Fragment>
                            {MostrarRespuesta ? (<>
                                <hr />
                                <RespuestaC aBoleto={Respuesta} />
                                <hr />
                            </>) : (<></>)}
                        </Fragment>

                    </div>
                    <div className='col-md-12 _pt-30'>
                        <h4 className='_c-p'>Importante</h4>
                        <p>Antes de solicitar su reembolso revise nuestras:</p>
                        <ul>
                            <li>Políticas y procedimientos <a href='https://aviorair.com/faq' className='hover-Oscuro _c-p' target='_Blank'><b>aviorair.com/faq</b></a></li>
                            <li>
                                Condiciones y beneficios de tarifas <a href='https://aviorair.com/tarifas' className='hover-Oscuro _c-p' target='_Blank'><b>aviorair.com/tarifas</b></a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            {/* <div className="_pos-r" id={'Top'}>
                <div className="theme-hero-area _h-desk-100vh" style={{ minHeight: "600px", height: "100vh" }}>
                    <div className="theme-hero-area-bg-wrap">
                        <div className="theme-hero-area-bg" id="hero-banner" style={{ backgroundImage: `url(${ImagenFondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}></div>
                        <div class="theme-hero-area-mask"></div>
                        <div class="blur-area" data-bg-area="#hero-banner" data-blur-area="#hero-search-form" data-blur="20"></div>
                    </div>
                    <div className="theme-hero-area-body _pos-desk-v-c _w-f _pv-mob-100">
                        <div className="container">
                            <div className="row row-col-mob-gap" data-gutter="60">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </>
    );
}

export default Indice;