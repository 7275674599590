import React, { useState, useEffect, Fragment } from 'react';
import ImagenFondo from './Header-beneficios.jpg';
import RespuestaC from './respuesta';
import LoaderScreen from '../layout/LoaderScreen';

import { ReembolsoSevices } from '../../Services';
import MensajeAdvertencia from '../../Services/MensajeAdvertencia';


const ConsultaFormulario = () => {
    const [Boleto, setBoleto] = useState(""); //BOLETO DEL RECLAMO 
    const [LongitudMaxBoleto] = useState(10);
    const [MostrarRespuesta, setMostrarRespuesta] = useState(false);
    const [ActivarLoader, setActivarLoader] = useState(false);
    const [Respuesta, setRespuesta] = useState([]);



    function solonumeros(inputtxt) {
        try {
            var numbers = /^[0-9]+$/;
            if (inputtxt.match(numbers)) {
                return true;
            }
            else {
                return false;
            }
        } catch (ex) {
            console.log('error', ex)
            return false;
        }
    }

    function AsignarValorBoleto(params) {
        if (solonumeros(params)) {
            if (params.length < LongitudMaxBoleto) {
                setBoleto(params);
            }
        }
    }

    async function BuscarResultado() {
        setActivarLoader(true);
        const peticion = await ReembolsoSevices.ObtenerEstatusReembolso(Boleto);
        // alert(JSON.stringify(peticion))
        if (peticion.status == 200) {
            setActivarLoader(false);
            if (peticion.resp.length > 0) {

                setRespuesta(peticion.resp);
                setMostrarRespuesta(true)
            }
            else {
                setMostrarRespuesta(false);
                MensajeAdvertencia("Advertencia", "No fué posible encontrar el número de solicitud ingresado. intente nuevamente", "warning", "Reintentar");

            }
        }
        else {
            setActivarLoader(false);
            setMostrarRespuesta(true);
        }
    }


    useEffect(() => {
        setBoleto("");
    }, [])
    return (
        <>

            <LoaderScreen props={ActivarLoader} mensaje={"Validando solicitud"} />
            <div class="theme-hero-area _h-desk-100vh">
                <div class="theme-hero-area-bg-wrap">
                    <div className="theme-hero-area-bg" id="hero-banner" style={{ backgroundImage: `url(${ImagenFondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}></div>
                    <div class="theme-hero-area-mask"></div>
                    <div class="blur-area" data-bg-area="#hero-banner" data-blur-area="#hero-search-form" data-blur="20"></div>
                </div>
                <div class="theme-hero-area-body _pos-desk-v-c _w-f">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 col-md-offset-1">
                                <div class="theme-search-area _pv-mob-60 theme-search-area-stacked theme-search-area-white">
                                    <div class="theme-search-area-header _ta-c">
                                        <h1 class="theme-search-area-title theme-search-area-title-lg">Consulta fácilmente tus reembolsos online</h1>
                                        <p class="theme-search-area-subtitle _c-w _op-1">A continuación, ingrese su número de solicitud de reembolso tal como aparece en el correo que le enviamos cuando la solicitud fue creada. Si aún no la ha hecho, podrá realizar la solicitud de reembolso haciendo <a className=" hover-Oscuro _c-white" href={'/'} rel="noopener noreferrer"><b>click aquí</b></a>.</p>
                                    </div>
                                    <div class="theme-search-area-form theme-search-area-form2" id="hero-search-form">
                                        <div class="row" data-gutter="none">
                                            <div class="col-md-11 ">
                                                <div class="theme-search-area-section first theme-search-area-section-curved">
                                                    <div class="theme-search-area-section-inner">
                                                        <input onChange={(ev) => AsignarValorBoleto(ev.target.value)} value={Boleto} class="theme-search-area-section-input typeahead" type="text" placeholder="Hotel Location" data-provide="typeahead" />
                                                    </div>

                                                </div>

                                            </div>


                                            <div class="col-md-1 ">
                                                <a href="#respuesta" onClick={() => BuscarResultado()} class="seleccionable theme-search-area-submit _mt-0 theme-search-area-submit-glow theme-search-area-submit-curved theme-search-area-submit-curved2">
                                                    <i class="theme-search-area-submit-icon fa fa-angle-right"></i>
                                                    <span class="_desk-h">Buscar</span>
                                                </a>
                                            </div>

                                        </div>

                                    </div>
                                    <small className=" _c-w">Ejemplo: 12345</small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="row">
                <div className="col-md-12">
                    <Fragment>
                        {MostrarRespuesta ? (<>
                            <RespuestaC aBoleto={Respuesta} />
                        </>) : (<></>)}
                    </Fragment>
                </div>
            </div>
        </>
    );
}
export default ConsultaFormulario;