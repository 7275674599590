import React, { useState, useEffect, Fragment } from 'react';
const RespuestaC = ({ aBoleto }) => {
    const [OcultarA, setOcultarA] = useState(false);
    const [Respuesta, setRespuesta] = useState([]);
    const [RespuestaObtenida, setRespuestaObtenida] = useState(false);
    var Lista = [];

    
    useEffect(() => {
        setOcultarA(false);
      try {
        if(aBoleto != null)
        {
            var objeto = JSON.stringify(aBoleto)
            if (objeto.length > 0) {
                setRespuestaObtenida(true);
            } else {
                setRespuestaObtenida(false);
            }
        }
       
      } catch (error) {
          window.location.href="/";
      }
      

    }, [])

    return (
        <>
         {RespuestaObtenida && OcultarA ? (<></>) : (<>
                    <>
                    <div class="theme-page-section _pt-90" style={{ minHeight: "50vh" }}>
                        <div class="container ">
                            <div className="" id="respuesta">
                                <div className="row">
                                    <div className="col-md-12 text-right"><p><a className=" seleccionable _c-dark hover-Oscuro "  href={'#Top'} ><i className="fa fa-arrow-up"></i></a></p>
                                   
                                      <div className="col-md-12 text-left"> <h3>Detalles de su solicitud de reembolso</h3></div> 
                                    </div>
                                    <div class="col-md-12 ">
                                         <h4 className=" text-right">N° solicitud<br />{aBoleto[0].SolicitudID} </h4> 
                                        <div class="theme-account-history" style={{overflowX:"auto"}}>
                                            <table class="table"  >
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Fecha</th>
                                                        <th>Hora</th>
                                                        <th>Estatus</th>
                                                        <th>Comentario</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.keys(aBoleto).forEach(valor => {
                                                        var Objeto = aBoleto[valor];
                                                        var EstatusColor = [];
                                                        switch (Objeto.EstatusID) {
                                                            case "A":
                                                                EstatusColor.push(
                                                                    <>
                                                                        <td class="theme-account-history-type">
                                                                            <i class="fa fa-circle theme-account-history-type-icon" style={{ color: "#D25A2F" }}></i>
                                                                        </td>
                                                                    </>
                                                                )
                                                                break;
                                                            case "C":
                                                                EstatusColor.push(
                                                                    <>
                                                                        <td class="theme-account-history-type">
                                                                            <i class="fa fa-circle theme-account-history-type-icon" style={{ color: "#DF922E" }}></i>
                                                                        </td>
                                                                    </>
                                                                )
                                                                break;
                                                            case "E":
                                                                EstatusColor.push(
                                                                    <>
                                                                        <td class="theme-account-history-type">
                                                                            <i class="fa fa-circle theme-account-history-type-icon" style={{ color: "#A9CAC9" }}></i>
                                                                        </td>
                                                                    </>
                                                                )
                                                                break;

                                                            case "N":
                                                                EstatusColor.push(
                                                                    <>
                                                                        <td class="theme-account-history-type">
                                                                            <i class="fa fa-circle theme-account-history-type-icon" style={{ color: "#6CA9B0" }}></i>
                                                                        </td>
                                                                    </>
                                                                )
                                                                break;
                                                            case "P":
                                                                EstatusColor.push(
                                                                    <>
                                                                        <td class="theme-account-history-type">
                                                                            <i class="fa fa-circle theme-account-history-type-icon" style={{ color: "#306795" }}></i>
                                                                        </td>
                                                                    </>
                                                                )
                                                                break;
                                                            case "R":
                                                                EstatusColor.push(
                                                                    <>
                                                                        <td class="theme-account-history-type">
                                                                            <i class="fa fa-circle theme-account-history-type-icon" style={{ color: "#332e2d" }}></i>
                                                                        </td>
                                                                    </>
                                                                )
                                                                break;

                                                            case "X":
                                                                EstatusColor.push(
                                                                    <>
                                                                        <td class="theme-account-history-type">
                                                                            <i class="fa fa-circle theme-account-history-type-icon" style={{ color: "#D7D8DC" }}></i>
                                                                        </td>
                                                                    </>
                                                                )
                                                                break;


                                                            default:
                                                                break;
                                                        }


                                                        Lista.push(
                                                            <tr>
                                                                {EstatusColor}
                                                                <td>
                                                                    <p class="theme-account-history-type-title">{Objeto.Fecha}</p>
                                                                </td>

                                                                <td class="theme-account-history-tr-date">
                                                                    <p class="theme-account-history-date">{Objeto.Hora}</p>
                                                                </td>
                                                                <td class="theme-account-history-tr-date">
                                                                    <p class="theme-account-history-date">{Objeto.Estatus} <span><b>({Objeto.EstatusID})</b></span></p>
                                                                </td>
                                                                <td class="theme-account-history-item-price">
                                                                    <p class="theme-account-history-date">{Objeto.Comentario}</p>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    {Lista}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                               
                                </div>
                            </div>
                        </div>
                    </div>
                </>
             
         </>)}
            
        </>
    );
}
export default RespuestaC;