import React, { useState, useEffect, Fragment } from 'react';

const Notfound = (props) => {

    return (
        <>
            <div class="theme-hero-area theme-hero-area-full">
                <div class="theme-hero-area-bg-wrap">
                    <div class="theme-hero-area-bg kenburns-top-left img-fondo-404" ></div>
                    <div class="theme-hero-area-mask theme-hero-area-mask-dark"></div>
                    <div class="theme-hero-area-inner-shadow"></div>
                </div>
                <div class="theme-hero-area-body theme-hero-area-body-vert-center">
                    <div class="container">
                        <div class="theme-page-404 _ta-c _ph-mob-30">
                            <h1 class="theme-page-404-title bounce-in-top sombra-texto">404</h1>
                            <p class="theme-page-404-subtitle sombra-texto">Página no encontrada</p>
                            <a class="btn btn-default theme-search-area-submit-curved _ph-100 _pv-10" href="/">Volver al inicio</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Notfound;