import React, { useEffect } from 'react';
import ConsultaFormulario from '../Consulta/consultaFormulario';
import Footer from './footer';
import Menu from './menu';

const LayoutConsultar = ({ props, mensaje }) => {


  return (
    <>
      <Menu />
      <div class="" style={{ minHeight: "100vh" }}>

        <ConsultaFormulario />

      </div>
      <Footer />
    </>
  );
}
export default LayoutConsultar;