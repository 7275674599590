import React, { useState, useEffect, Fragment } from 'react';
import swal from 'sweetalert';


const MensajeAdvertencia = (Titulo,Mensaje,TipoMensaje,TextoBoton) => {

    return swal({
            title: Titulo,
            text: Mensaje,
            closeOnClickOutside: false,
            butswaton: TextoBoton,
            icon: TipoMensaje,
        })
    
}

export default MensajeAdvertencia;