import React, { useState, useEffect, Fragment } from 'react';
import { ReembolsoSevices } from "../../Services/";
import LoaderScreen from '../layout/LoaderScreen';
import MensajeAvior from './MensajeAdvertencia'
const Paso2 = (props) => {
    const [ActivarLoader, setActivarLoader] = useState(false);
    const [MensajeLoader, setMensajeLoader] = useState("");
    // -------------------------------------------------------------------------------------------------
    // VARIABLES Y CONSTANTES
    // -------------------------------------------------------------------------------------------------
    const [FormaDepago, setFormaDepago] = useState([]);
    const [Origenes, setOrigenes] = useState([]);
    const [Destinos, setDestinos] = useState([]);
    const [Monedas, setMonedas] = useState([]);
    const [TipoPasajeros, setTipoPasajeros] = useState([]);
    const [ErrorEmailMsg, setErrorEmailMsg] = useState("");
    const [PaisOrigen, setPaisOrigen] = useState("");
    const [PoseeBoleto, setPoseeBoleto] = useState(false);
    const [Paises, setPaises] = useState([]);
    const [DocumentoPorPais, setDocumentoPorPais] = useState([]);
    var ListaOrigenes = [];
    var ListaPaises = [];
    var ListaDocumentoPorPais = [];
    var ListaTipoPasajero = [];
    var ListaMoneda = [];
    var ListaFormaPago = [];
    var ListaDestinos = [];
    const [ErrorNombresMsg, setErrorNombresMsg] = useState("");
    const [ErrorApellidosMsg, setErrorApellidosMsg] = useState("");
    const [ErrorOrigensMsg, setErrorOrigensMsg] = useState("");
    const [ErrorDestinoMsg, setErrorDestinoMsg] = useState("");
    const [ErrorTipoPasajeroMsg, setErrorTipoPasajeroMsg] = useState("");
    const [ErrorMonedaMsg, setErrorMonedaMsg] = useState("");
    const [ErrorFormaPagoMsg, setErrorFormaPagoMsg] = useState("");
    const [Correo, setCorreo] = useState("");
    const [FormaPago, setFormaPago] = useState("");
    const [Nombres, setNombres] = useState("");
    const [Apellidos, setApellidos] = useState("");
    const [DocumentoReceptor, setDocumentoReceptor] = useState("");
    const [OrigenSelecionado, setOrigenSelecionado] = useState("");
    const [DestinoSelecionado, setDestinoSelecionado] = useState("");
    const [TipoPasajeroSelecionado, setTipoPasajeroSelecionado] = useState("");
    const [MonedaSeleccionado, setMonedaSeleccionado] = useState("");
    const [TipoDocumento, setTipoDocumento] = useState("");
    // -------------------------------------------------------------------------------------------------
    // VALIDACIONES 
    // -------------------------------------------------------------------------------------------------
    function validarCorreo(inputtxt) {
        try {
            var numbers = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;;
            if (inputtxt.match(numbers)) {
                return true;
            }
            else {
                return false;
            }
        } catch (ex) {
            return false;
        }
    }
    function solotexto(inputtxt) {
        var letters = /^[A-Za-z ]+$/;
        if (inputtxt.match(letters)) {
            return true;
        }
        else {
            return false;
        }

    }
    // -------------------------------------------------------------------------------------------------
    // FUNCIONES 
    // -------------------------------------------------------------------------------------------------
    async function ObtenerOrigenes() {
        
        const peticion = await ReembolsoSevices.ObtenerOrigenes();
        if (peticion.status == 200) {
            if (peticion.resp != null) {
                setOrigenes(peticion.resp);
            }
            else {
                setErrorOrigensMsg("Ocurrió un error al cargar los origenes.");
            }
        }
        else {
            setErrorOrigensMsg("Ocurrió un error al cargar los origenes.");
        }
    }
    function LimpiarLocalStorage() {
        window.localStorage.setItem("Paso2", btoa(false));
        window.localStorage.removeItem("Paso3", btoa(false));
        window.localStorage.removeItem("Paso4", btoa(false));
    }
    async function ObtenerDocumentosPorPais(Pais) {

        if (Pais == null) {

        } else {
            setActivarLoader(true);
            setMensajeLoader("Por favor espere...");
            const peticion = await ReembolsoSevices.ObtenerDocumentosPorPaises(Pais);
            if (peticion.status == 200) {
                if (peticion.resp != null) {
                    setActivarLoader(false);
                    setDocumentoPorPais(peticion.resp);
                }
            }
            else {
                // setErrorTipoPasajeroMsg("Ocurrió un error al cargar la lista de paises.");
            }
        }

    }
    async function ObtenerPaises() {
        const peticion = await ReembolsoSevices.ObtenerPaises();
        if (peticion.status == 200) {
            if (peticion.resp != null) {
                setPaises(peticion.resp);
            }
        }
        else {
            setErrorTipoPasajeroMsg("Ocurrió un error al cargar la lista de paises.");
        }
    }
    async function ObtenerPasajeros() {
        const peticion = await ReembolsoSevices.ObtenerTipoPasajero();
        if (peticion.status == 200) {
            if (peticion.resp != null) {
                setTipoPasajeros(peticion.resp);
            }
        }
        else {
            setErrorTipoPasajeroMsg("Ocurrió un error al cargar los tipos de pasajero.");
        }
    }
    async function ObtenerMoneda() {
        const peticion = await ReembolsoSevices.ObtenerMonedas();
        
        if (peticion.status == 200) {
            if (peticion.resp != null) {
                setMonedas(peticion.resp);
                setActivarLoader(false)
            }
        }
        else {
            setErrorMonedaMsg("Ocurrió un error al cargar las monedas.");
        }
    }
    async function ObtenerFormaDePago() {
        setActivarLoader(true);
        setMensajeLoader("Por favor espere...");
        var PoseeBoleto = window.localStorage.getItem("PoseeBoleto");
        var Moneda = window.localStorage.getItem("Moneda");
        const peticion = await ReembolsoSevices.ObtenerFormaPago(Moneda, PoseeBoleto);
        if (peticion.status == 200) {
            if (peticion.resp != null) {
                setFormaDepago(peticion.resp);
                setActivarLoader(false);
            }
        }
        else {
            setErrorTipoPasajeroMsg("Ocurrió un error al cargar los tipos de pasajero.");
        }

    }
    // ASIGNAR ORIGEN Y OBTENER DESTINO
    async function ObtenerDestino(params) {
        if (params != "") {
            setDestinos([]);
            setDestinoSelecionado("");
            setActivarLoader(true);
            setMensajeLoader("Obteniendo destinos...");
            setOrigenSelecionado(params);
            window.localStorage.setItem("Origen", params);
            setErrorOrigensMsg("");
            const peticion = await ReembolsoSevices.ObtenerDestino(params);
            
            if (peticion.status == 200) {
                if (peticion.resp != null) {
                    setDestinos(peticion.resp);
                    setActivarLoader(false)
                }
            }
            else {
                setErrorDestinoMsg("- Error en destinos.");
            }
        }
        else {
            setDestinos([]);
            setOrigenSelecionado("");
            window.localStorage.removeItem("Origen");
            window.localStorage.removeItem("Destino");
            setDestinoSelecionado("");
            setErrorOrigensMsg("- Campo obligatorio")
        }
    }
    function asignarDestino(params) {
        if (params != "") {
            //LOCALSTORAGE
            window.localStorage.setItem("Destino", params);
            setDestinoSelecionado(params);
            setErrorDestinoMsg("")
        } else {
            window.localStorage.removeItem("Destino");
            setDestinoSelecionado("");
            setErrorDestinoMsg("- Campo obligatorio")
        }
    }
    function ValidarPoseeBoleto() {
        var PoseBoletos = window.localStorage.getItem("PoseeBoleto")
        if (PoseBoletos == "T") {
            setPoseeBoleto(true)
        }
    }
    // -------------------------------------------------------------------------------------------------
    // ASIGNACIONES 
    // -------------------------------------------------------------------------------------------------
    function asignarDocumentoReceptor(params) {
        if (params != "") {
            // setErrorApellidosMsg("");
            // if (solotexto(params)) {
            if (params.length <= 20) {
                //LOCALSTORAGE
                window.localStorage.setItem("PasajeroDocumento", params);
                setDocumentoReceptor(params);
                CamposObligatoriosCorrectos();
            }

            // }
            // else {
            //     // seterror("Debe ingresar solo texto");
            // }
        }
        else {
            setDocumentoReceptor("");
            // setErrorApellidosMsg("- Campo obligatorio");
            window.localStorage.removeItem("PasajeroDocumento");
        }
    }
    function AsignarTipoDocumento(params) {
        setTipoDocumento(params);
        if (params != "") {
        window.localStorage.setItem("PasajeroTipoDocumentoSolicitante", params);
        CamposObligatoriosCorrectos();
        }
        else{
            window.localStorage.removeItem("PasajeroTipoDocumentoSolicitante");  
        }
    }
    function AsignarPaisOrigen(params) {
        setPaisOrigen(params);
        if (params != "") {
            setDocumentoPorPais([]);
            ObtenerDocumentosPorPais(params);
            window.localStorage.setItem("PasajeroPaisDocumento", params);
            CamposObligatoriosCorrectos();
        }else{
            window.localStorage.removeItem("PasajeroPaisDocumento");
            window.localStorage.removeItem("PasajeroTipoDocumentoSolicitante");
            setDocumentoPorPais([]);
            
        }
        
    }
    function asignarCorreo(params) {
        setCorreo(params);
        if (params != "") {
            if (validarCorreo(params)) {
                //LOCALSTORAGE
                window.localStorage.setItem("Correo", params);
                setErrorEmailMsg("");
                CamposObligatoriosCorrectos();
            }
            else {
                setErrorEmailMsg("Formato de correo erróneo");
                window.localStorage.removeItem("Correo");
                CamposObligatoriosCorrectos();
            }
        }
        else {
            setErrorEmailMsg("");
            window.localStorage.removeItem("Correo");
        }
    }
    function asignarFormaPago(params) {
        if (params != "") {
            //LOCALSTORAGE
            window.localStorage.setItem("FormaPago", params);
            setFormaPago(params);
            setErrorFormaPagoMsg("");
            CamposObligatoriosCorrectos();
        } else {
            window.localStorage.removeItem("FormaPago");
            setFormaPago("");
            setErrorFormaPagoMsg("- Campo obligatorio");
            CamposObligatoriosCorrectos();
        }
    }
    function asignarNombres(params) {
        if (params != "") {
            setErrorNombresMsg("");
            if (solotexto(params)) {
                //LOCALSTORAGE
                if (params.length <= 50) {
                    window.localStorage.setItem("Nombres", params);
                    setNombres(params);
                }
            }
            else {
                setErrorNombresMsg("Debe ingresar solo texto");
            }
        }
        else {
            setNombres("");
            window.localStorage.removeItem("Nombres");
            setErrorNombresMsg("- Campo obligatorio");
        }
        CamposObligatoriosCorrectos();
    }

    function asignarApellidos(params) {
        if (params != "") {
            setErrorApellidosMsg("");
            if (solotexto(params)) {
                if (params.length <= 50) {
                    //LOCALSTORAGE
                    window.localStorage.setItem("Apellidos", params);
                    setApellidos(params);
                }

            }
            else {
                setErrorApellidosMsg("Debe ingresar solo texto");
            }
        }
        else {
            setApellidos("");
            setErrorApellidosMsg("- Campo obligatorio");
            window.localStorage.removeItem("Apellidos");
        }
        CamposObligatoriosCorrectos();
    }

    function asignarTipoPasajero(params) {
        if (params != "") {
            //LOCALSTORAGE
            window.localStorage.setItem("TipoPasajero", params);
            setTipoPasajeroSelecionado(params);
            setErrorTipoPasajeroMsg("");
        } else {
            window.localStorage.removeItem("TipoPasajero");
            setErrorTipoPasajeroMsg("- Campo obligatorio");
        }
        CamposObligatoriosCorrectos();
    }

    function asignarMoneda(params) {
        if (params != "") {
            //LOCALSTORAGE
            window.localStorage.setItem("Moneda", params);
            setMonedaSeleccionado(params);
            // OBTENER LA FORMA DE PGO DEGUN LA MONEDA 
            setFormaDepago([]);
            setFormaPago("");
            ObtenerFormaDePago();
            setErrorMonedaMsg("");
        } else {
            window.localStorage.removeItem("Moneda");
            setMonedaSeleccionado("");
            window.localStorage.removeItem("FormaPago");
            setFormaPago("");
            setFormaDepago([])
            setErrorMonedaMsg("- Campo obligatorio");
        }
        CamposObligatoriosCorrectos();
    }
    // -------------------------------------------------------------------------------------------------
    // VALIDACIONES FINALES 
    // -------------------------------------------------------------------------------------------------
    function CamposObligatoriosCorrectos() {
        var PoseBoletos = window.localStorage.getItem("PoseeBoleto");
        var Paso1Validado = window.localStorage.getItem("Paso1");
        switch (PoseBoletos) {
            case "S":
                if (Paso1Validado == btoa(true)) {
                    // SI EL CLIENTE NO POSEE BOLETOS
                    // SOLO VALIDAR SI EXISTE EL CORREO Y LA FORMA DE PAGO 
                    var Correo = window.localStorage.getItem("Correo");
                    var FormaPago = window.localStorage.getItem("FormaPago");
                    if (Correo != null) {
                        if (FormaPago != null) {
                            window.localStorage.setItem("Paso2", btoa(true));
                        }
                        else {
                            window.localStorage.setItem("Paso2", btoa(false));
                        }
                    }
                    else {
                        window.localStorage.setItem("Paso2", btoa(false));
                    }
                }
                break;
            case "T":
                // SI EL CLIENTE SI POSEE BOLETOS
                // VALIDAR TODOS LOS DATOS EPRSONALES
                if (Paso1Validado == btoa(true)) {
                    var Nombres = window.localStorage.getItem("Nombres");
                    var Apellidos = window.localStorage.getItem("Apellidos");
                    var FormaPagoF = window.localStorage.getItem("FormaPago");
                    var CorreoF = window.localStorage.getItem("Correo");
                    var Origen = window.localStorage.getItem("Origen");
                    var Destino = window.localStorage.getItem("Destino");


                    var PasajeroPaisDocumento = window.localStorage.getItem("PasajeroPaisDocumento");
                    var PasajeroTipoDocumentoSolicitante = window.localStorage.getItem("PasajeroTipoDocumentoSolicitante");
                    var PasajeroDocumento = window.localStorage.getItem("PasajeroDocumento");



                    if (Nombres != null && Apellidos != null && FormaPagoF != null && CorreoF != null && Origen != null && Destino != null) {


                        if (PasajeroPaisDocumento != null && PasajeroTipoDocumentoSolicitante != null && PasajeroDocumento != null) {
                            window.localStorage.setItem("Paso2", btoa(true));
                        }
                        else {
                            window.localStorage.setItem("Paso2", btoa(false));
                        }

                    }
                    else {
                        window.localStorage.setItem("Paso2", btoa(false));
                    }

                }
                break;

            default:
                window.localStorage.setItem("PoseeBoleto", false);
                break;
        }
    }

    useEffect(() => {
        var PoseBoletos = window.localStorage.getItem("PoseeBoleto");
        if (PoseBoletos == "T") {
            ValidarPoseeBoleto();
            setActivarLoader(true);
            setMensajeLoader("Obteniendo origenes y monedas...");
            ObtenerOrigenes();
            ObtenerPasajeros();
            ObtenerMoneda();
            ObtenerPaises();
        }
        else {
            ValidarPoseeBoleto();
            setActivarLoader(true);
            setMensajeLoader("Obteniendo monedas...");
            ObtenerMoneda();
        }
        LimpiarLocalStorage();
    }, []);

    return (
        <>
            <>
                <LoaderScreen props={ActivarLoader} mensaje={MensajeLoader} />
                <div className="_pt-30">
                    <div class="theme-page-section-header _ta-l">
                        <h5 class="theme-page-section-title">Datos del boleto</h5>
                        <h5 className="feature-subtitle _op-06">Todos los campos habilitados y marcados con asteriscos (*) son obligatorios.</h5>
                        <hr className=" style-3"></hr>
                    </div>
                    <div class="row row-col-gap " data-gutter="20">
                        <Fragment>
                            {PoseeBoleto ? (<>
                                <div class="col-md-6 ">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Nombre(s) del pasajero <span className=" _c-p">{ErrorNombresMsg}</span></h5>
                                    <div class="theme-payment-page-form-item form-group">
                                        <input
                                            value={Nombres}
                                            onChange={(ev) => asignarNombres(ev.target.value)}
                                            class="form-control"
                                            type="text"
                                            placeholder="Nombre(s)"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-6 ">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Apellido(s) del pasajero  <span className=" _c-p">{ErrorApellidosMsg}</span></h5>
                                    <div class="theme-payment-page-form-item form-group">
                                        <input
                                            value={Apellidos}
                                            onChange={(ev) => asignarApellidos(ev.target.value)}
                                            class="form-control"
                                            type="text"
                                            placeholder="Apellido(s)"
                                        />

                                    </div>
                                </div>
                                <div class="col-md-6 ">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Origen  <span className=" _c-p">{ErrorOrigensMsg}</span></h5>
                                    <div class="theme-payment-page-form-item form-group">
                                        <i class="fa fa-angle-down"></i>
                                        <select class="form-control seleccionable"
                                            onChange={(ev) => ObtenerDestino(ev.target.value)}
                                            value={OrigenSelecionado}
                                        >
                                            <option value={""}>Seleccione</option>
                                            {Object.keys(Origenes).forEach(valor => {
                                                var Objeto = Origenes[valor];
                                                ListaOrigenes.push(
                                                    <option value={Objeto.ID}>{Objeto.Description}</option>
                                                );
                                            })}
                                            {ListaOrigenes}
                                        </select>

                                    </div>
                                </div>
                                <div class="col-md-6 ">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Destino <span className=" _c-p">{ErrorDestinoMsg}</span></h5>
                                    <div class="theme-payment-page-form-item form-group">
                                        <i class="fa fa-angle-down"></i>
                                        <select class="form-control seleccionable"
                                            onChange={(ev) => asignarDestino(ev.target.value)} value={DestinoSelecionado}>
                                            <option value={""}>Seleccione</option>
                                            {Object.keys(Destinos).forEach(valor => {
                                                var Objeto = Destinos[valor];
                                                ListaDestinos.push(
                                                    <option value={Objeto.ID}>{Objeto.Description}</option>
                                                );
                                            })}
                                            {ListaDestinos}
                                        </select>

                                    </div>
                                </div>
                                <div class="col-md-6 ">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Tipo de pasajero <span className=" _c-p">{ErrorTipoPasajeroMsg}</span></h5>
                                    <div class="theme-payment-page-form-item form-group">
                                        <i class="fa fa-angle-down"></i>
                                        <select class="form-control seleccionable"
                                            onChange={(ev) => asignarTipoPasajero(ev.target.value)}
                                            value={TipoPasajeroSelecionado}>
                                            <option value={""}>Seleccione</option>
                                            {Object.keys(TipoPasajeros).forEach(valor => {
                                                var Objeto = TipoPasajeros[valor];
                                                ListaTipoPasajero.push(
                                                    <option value={Objeto.ID}>{Objeto.Description}</option>
                                                );
                                            })}
                                            {ListaTipoPasajero}
                                        </select>

                                    </div>
                                </div>

                            </>) : (<></>)}
                        </Fragment>
                        <div class="col-md-6 ">
                            <h5 className=" _c-dark"><span className="_c-p">*</span>  Moneda <span className=" _c-p">{ErrorMonedaMsg}</span></h5>
                            <div class="theme-payment-page-form-item form-group">
                                <i class="fa fa-angle-down"></i>
                                <select class="form-control seleccionable"
                                    onChange={(ev) => asignarMoneda(ev.target.value)}
                                    value={MonedaSeleccionado}
                                >
                                    <option value={""}>Seleccione</option>
                                    {Object.keys(Monedas).forEach(valor => {
                                        var Objeto = Monedas[valor];
                                        ListaMoneda.push(
                                            <option value={Objeto.ID}>{Objeto.Description}</option>
                                        );
                                    })}
                                    {ListaMoneda}
                                </select>

                            </div>
                        </div>
                        <div class="col-md-6 ">
                            <h5 className=" _c-dark"><span className="_c-p">*</span>  Forma de pago  <span className=" _c-p">{ErrorFormaPagoMsg}</span></h5>
                            <div class="theme-payment-page-form-item form-group">
                                <i class="fa fa-angle-down"></i>
                                <select class="form-control seleccionable"
                                    placeholder={"Seleccione una opción"}
                                    onChange={(ev) => asignarFormaPago(ev.target.value)} value={FormaPago}>
                                    <option value={""} >Seleccione</option>
                                    {Object.keys(FormaDepago).forEach(valor => {
                                        var Objeto = FormaDepago[valor];
                                        ListaFormaPago.push(
                                            <option value={Objeto.ID}>{Objeto.Description}</option>
                                        );
                                    })}
                                    {ListaFormaPago}
                                </select>

                            </div>
                        </div>
                        <div class="col-md-6 ">
                            <h5 className=" _c-dark"><span className="_c-p">*</span>  Email del solicitante  <span className=" _c-p">{ErrorEmailMsg}</span></h5>
                            <div class="theme-payment-page-form-item form-group">
                                <input
                                    value={Correo}
                                    onChange={(ev) => asignarCorreo(ev.target.value)}
                                    class="form-control"
                                    type="text"
                                    placeholder="solcitante@correo.com"
                                />

                            </div>
                        </div>
                        <Fragment>
                            {PoseeBoleto ? (<>
                                <div className="col-md-12">
                                    <div class="theme-page-section-header _ta-l _pt-20">
                                        <h5 class="theme-page-section-title" style={{ fontSize: "28px" }}>Documento del pasajero</h5>
                                        <h5 className="feature-subtitle _op-06">Ingrese los datos del documento del pasajero.</h5>
                                        <hr className=" style-3"></hr>
                                    </div>
                                </div>
                                <div class="col-md-4 ">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Origen del documento  <span className=" _c-p"></span></h5>
                                    <div class="theme-payment-page-form-item form-group">
                                        <i class="fa fa-angle-down"></i>
                                        <select class="form-control seleccionable"
                                            onChange={(ev) => AsignarPaisOrigen(ev.target.value)}
                                            value={PaisOrigen}
                                        >
                                            <option value={""}>Seleccione</option>
                                            {Object.keys(Paises).forEach(valor => {
                                                var Objeto = Paises[valor];
                                                ListaPaises.push(
                                                    <option value={Objeto.ID}>{Objeto.Description}</option>
                                                );
                                            })}
                                            {ListaPaises}
                                        </select>

                                    </div>
                                </div>


                                <div class="col-md-4 ">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Tipo de documento  <span className=" _c-p"></span></h5>
                                    <div class="theme-payment-page-form-item form-group">
                                        <i class="fa fa-angle-down"></i>
                                        <select class="form-control seleccionable"
                                            onChange={(ev) => AsignarTipoDocumento(ev.target.value)}
                                            value={TipoDocumento}
                                        >
                                            <option value={""}>Seleccione</option>
                                            {Object.keys(DocumentoPorPais).forEach(valor => {
                                                var Objeto = DocumentoPorPais[valor];
                                                ListaDocumentoPorPais.push(
                                                    <option value={Objeto.ID}>{Objeto.Description}</option>
                                                );
                                            })}
                                            {ListaDocumentoPorPais}
                                        </select>

                                    </div>
                                </div>
                                <div class="col-md-4 ">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Número de documento  <span className=" _c-p"></span></h5>
                                    <div class="theme-payment-page-form-item form-group">
                                        <input
                                            value={DocumentoReceptor}
                                            onChange={(ev) => asignarDocumentoReceptor(ev.target.value)}
                                            class="form-control"
                                            type="text"
                                            placeholder="Doc. Receptor"
                                        />
                                    </div>
                                </div>
                            </>) : (<></>)}
                        </Fragment>
                        <MensajeAvior />
                    </div>

                </div>
            </>

        </>
    );
}

export default Paso2;