import React, { useState, useEffect, Fragment } from 'react';
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import Moment from 'moment';
import Popover from 'react-tiny-popover';
import MensajeAvior from './MensajeAdvertencia';
import { ReembolsoSevices } from '../../Services';
import MensajeAdvertencia from '../../Services/MensajeAdvertencia';
import LoaderScreen from '../layout/LoaderScreen';
import 'moment/locale/es';

const Paso1 = (props) => {
    //VARIABLES

    Moment.locale('es');
    const [ActivarLoader, setActivarLoader] = useState(false);
    var hoy = new Date();
    var anoPasado = hoy.setMonth(hoy.getMonth() - 12);
    require('moment/locale/es'); // FORMATEAR FECHA EN ESPAÑOL
    const [FechaBoleto, setFechaBoleto] = useState(Moment(new Date())); // VALOR FECHA DE SOLO IDA
    const [ActivarPopoverFechas, setActivarPopoverFechas] = useState(false);
    const [Boleto, setBoleto] = useState(""); //BOLETO DEL RECLAMO 
    const [ErrorMsg, setErrorMsg] = useState("");
    const [LongitudMaxBoleto] = useState(14);
    const [TieneBoletos, setTieneBoletos] = useState(false);
    // -------------------------------------------------------------------------------------------------
    // FUNCIONES 
    // -------------------------------------------------------------------------------------------------
    // FUNCION SOLO PERMITIR SOLO NUMERO
    function solonumeros(inputtxt) {
        try {
            var numbers = /^[0-9]+$/;
            if (inputtxt.match(numbers)) {
                return true;
            }
            else {
                return false;
            }
        } catch (ex) {
            console.log('error', ex)
            return false;
        }
    }
    // -------------------------------------------------------------------------------------------------
    // ASIGNACIONES 
    // -------------------------------------------------------------------------------------------------
    // BOLETO
    function AsignarNumeroBoleto(params) {
       
        if (solonumeros(params)) {
            if (params.length < LongitudMaxBoleto) {
                console.log(params[0])
                setErrorMsg(" - El boleto debe tener 13 dígitos númericos.");
                window.localStorage.setItem("Boleto", params);
                setBoleto(params)
            }
        }
        else {   
            if (params == "") {
                window.localStorage.removeItem("Boleto");
                setBoleto(params)
            }
        }
        CamposObligatoriosCorrectos();
    }
    // FECHA DE BOLETO
    function AsignarFecha(value) {
        setFechaBoleto(value);
        setActivarPopoverFechas(false);
        //LOCALSTORE
        window.localStorage.setItem("FechaBoleto", value);
        CamposObligatoriosCorrectos();
    }
    function FormatoTicket(cod) {
        if (cod.length > 4) {
            if (cod[0] == 7 && cod[1] == 4 && cod[2] == 2) {
            }
            else {
                setBoleto("");
                setErrorMsg(" - Formato de ticket erróneo");
                window.localStorage.removeItem("Boleto");
            }
        }
    }
    function LimpiarLocalStorage() {
        window.localStorage.clear();
        window.localStorage.setItem("Paso1", btoa(false));
        window.localStorage.setItem("PoseeBoleto", "S");
        window.localStorage.removeItem("Paso3", btoa(false));
        window.localStorage.removeItem("Paso2", btoa(false));
        window.localStorage.removeItem("Paso4", btoa(false));
        setTieneBoletos(false)
        setBoleto("");
        setFechaBoleto(Moment(new Date()));
    }

    //VALIDAR SI POSEE BOLETOS
    function ValidarSiPoseeBoletos(params) {
        if (params == "true") {
            setTieneBoletos(true);
            setBoleto("");
            window.localStorage.setItem("PoseeBoleto", "T");
            window.localStorage.setItem("FechaBoleto", new Date());
            window.localStorage.setItem("Paso1", btoa(false));
        }
        else {
            setTieneBoletos(false);
            setBoleto("");
            setFechaBoleto(Moment(new Date()));
            window.localStorage.setItem("Paso1", btoa(true));
            window.localStorage.setItem("PoseeBoleto", "S");
        }
        CamposObligatoriosCorrectos();
    }



    //INICIAR
    useEffect(() => {

        
        LimpiarLocalStorage();
        window.localStorage.setItem("Paso1", btoa(true));
        window.localStorage.setItem("PoseeBoleto", "S");
        CamposObligatoriosCorrectos();
    }, []);
    //VALIDAR SI TODOS LOS CAMPOS NECESARIOS EXISTEN
    async function CamposObligatoriosCorrectos() {
        var CampoPoseeBoleto = window.localStorage.getItem("PoseeBoleto");
        switch (CampoPoseeBoleto) {
            case "S":
                // SI EL CLIENTE NO POSEE BOLETOS
                // INDICAR QUE ESTA VALIDADO EL PASO 1 Y PUEDE CONTINUAR AL PASO 2
                window.localStorage.setItem("Paso1", btoa(true));
                break;
            case "T":
                //SI EL CLIENTE SI POSEE BOLETOS 
                //DEBE VALIDAR LA FECHA Y NUMERO DE BOLETO PARA INDICAR QUE PUEDE CONTINUAR AL PASO 2    
                var Boleto = window.localStorage.getItem("Boleto");
                if (Boleto != null ) {
                    if (Boleto.length == (LongitudMaxBoleto - 1)) {
                        setErrorMsg("");
                        window.localStorage.setItem("Paso1", btoa(true));
                    } else {
                        window.localStorage.setItem("Paso1", btoa(false));
                    }
                }
                break;
            default:
                window.localStorage.setItem("Paso1", btoa(false));
                break;
        }
    }

    return (
        <>
            <LoaderScreen props={ActivarLoader} mensaje={"Validando boleto"} />

            <div className="_pt-30">
                <div class="theme-page-section-header _ta-l">
                    <h5 class="theme-page-section-title">Validación</h5>
                    <h5 className="feature-subtitle _op-06">Todos los campos habilitados y marcados con asteriscos (*) son obligatorios.</h5>
                    <hr className=" style-3"></hr>
                </div>
                <div class="row row-col-gap " data-gutter="20">
                    <div class="col-md-6 ">
                        <h5 className=" _c-dark"><span className="_c-p">*</span> ¿Usted posee un boleto?</h5>
                        <div class="theme-payment-page-form-item form-group">
                            <i class="fa fa-angle-down"></i>
                            <select class="form-control seleccionable" value={TieneBoletos} onChange={(params) => ValidarSiPoseeBoletos(params.target.value)}>
                                <option value={true}>Si</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                    </div>
                </div>
                {TieneBoletos ? (
                    <>
                        <Fragment>
                            <div class="row row-col-gap _pt-30" data-gutter="20">
                                <div class="col-md-6 ">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Número de boleto <span className=" _c-p">{ErrorMsg}</span></h5>
                                    <div class="theme-payment-page-form-item form-group">
                                        <input
                                            class="form-control"
                                            type="text"
                                            onChange={(ev) => AsignarNumeroBoleto(ev.target.value)}
                                            onKeyUp={(ev) => FormatoTicket(ev.target.value)}
                                            placeholder="742..."
                                            value={Boleto}
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6 ">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span> Fecha de compra</h5>
                                    <div class="theme-payment-page-form-item form-group">
                                        <Popover
                                            containerClassName={"text-center"}
                                            containerStyle={{ borderRadius: '0px 0px 10px 10px', border: '1.2px solid #CD171A', background: "#f8f8f8", zIndex: "999" }}
                                            arrowStyle={{ background: "#f8f8f8" }}
                                            isOpen={ActivarPopoverFechas}
                                            position={'bottom'}
                                            disableReposition
                                            onClickOutside={() => setActivarPopoverFechas(false)}
                                            content={({ position, nudgedLeft, nudgedTop, targetRect, popoverRect }) => (
                                                <>
                                                    <DateRangePicker
                                                        minimumDate={'01-01-2019'}
                                                        maximumDate={new Date()}
                                                        selectedLabel={'Fecha en la cual compró el boleto'}
                                                        locale={Moment.locale('es')}
                                                        showLegend={true}
                                                        firstOfWeek={1}
                                                        numberOfCalendars={1}
                                                        value={FechaBoleto}
                                                        onSelect={(ev) => AsignarFecha(ev)}
                                                        singleDateRange={false}
                                                        selectionType={'single'}
                                                    />
                                                </>
                                            )}
                                        >
                                            <input class="form-control seleccionable"
                                                onClick={() => setActivarPopoverFechas(!ActivarPopoverFechas)}
                                                type="text"
                                                value={Moment(FechaBoleto).format("YYYY/MM/DD")}
                                                placeholder="DD/MM/AAAA" />

                                        </Popover>
                                    </div>
                                </div>
                                <MensajeAvior />
                            </div>
                        </Fragment>
                    </>) : (<></>)}

            </div>
        </>
    );
}

export default Paso1;