import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Notfound from '../404';
import LayoutConsultar from '../layout/LayoutConsultar';
import LayoutFormulario from '../layout/layoutFormulario';

import LayoutInicio from '../layout/LayoutInicio';

const Router = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path='/' component={LayoutInicio} />
            <Route exact path='/solicitar' component={LayoutFormulario} />
            <Route exact path='/consultar' component={LayoutConsultar} />
            <Route component={Notfound} />
        </Switch>
    </BrowserRouter>
);

export default Router;
