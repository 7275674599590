import React, { useState, useEffect, Fragment } from 'react';

const MensajeAvior = (props) => {
    const [mensajeAdvertencia1] = useState("Avior Airlines, C.A recopila información y datos para la correcta ejecución y cumplimiento del proceso de gestión de su solicitud de reembolso, los mismos no serán compartidos ni divulgados para otro fin.");
    const [mensajeAdvertencia2] = useState("Al hacer clic en enviar, usted manifiesta su conformidad para suministrar los mismos, teniendo en cuenta que sin los datos completos no se podrá formalizar su requerimiento.");

    return (
        <>
            <div className="col-md-12 _pt-50">
                <div class="theme-search-results-sign-in bg-grad " style={{ borderRadius: "10px" }}>
                    <i class="theme-search-results-sign-in-icon fa fa-info"></i>
                    <h5 class="theme-search-results-sign-in-title">
                        {mensajeAdvertencia1}
                        <br/>
                        {mensajeAdvertencia2}
                    </h5>
                  
                </div>
            </div>
        </>
    );
}

export default MensajeAvior;