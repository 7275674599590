import React, { useState, useEffect, Fragment } from 'react';
const Bienvenida = (props) => {

    useEffect(() => {


        window.localStorage.clear();
    }, []);

    return (
        <>
            <div className="theme-item-page-details-section _pb-30">
                <div className=" _pt-100 _pb-100">
                    <div className="row">
                        <div className="col-md-12 text-center  _pb-20">
                            <img src={`${process.env.PUBLIC_URL}/img/Reembolsos.svg`} alt="imagen" className="_pt-10 _pl-15 " style={{ width: "80px" }} />
                        </div>
                        <div className="col-md-12 text-center ">
                            <h2>Bienvenido</h2>
                            <h1 style={{ color: "#ad0d17" }}>IMPORTANTE</h1>
                            <h2 style={{ color: "#ad0d17" }}><b>Debe realizar solicitudes por cada boleto que requiera reembolsar
                            </b></h2>
                        </div>
                        <div className="col-md-12 _pt-30 text-justify">
                            <p className="text-justify _pt-30" style={{ fontSize: "20px" }}>
                                Una vez completado el proceso de inclusión de los datos requeridos <b>por cada boleto</b>, se <b>asignará</b> de manera  inmediata un <b>Número de Solicitud</b>, <i>único e intransferible</i>,  que será el <b>código de identificación de su caso</b> y le permitirá consultar el status de su requerimiento en nuestra página web o en cualquier oficina comercial de
                                <b>Avior Airlines.</b>
                            </p>

                            <h4 className='_mt-50'><b>Antes de solicitar su reembolso revise nuestras:</b></h4>
                        
                            <ul>
                                <li>Políticas y procedimientos <a href='https://aviorair.com/faq' className='hover-Oscuro _c-p' target='_Blank'><b>aviorair.com/faq</b></a></li>
                                <li>
                                    Condiciones y beneficios de tarifas <a href='https://aviorair.com/tarifas' className='hover-Oscuro _c-p' target='_Blank'><b>aviorair.com/tarifas</b></a>. Tenga en cuenta que en algunos casos aplican cargos administrativos sobre la tarifa base del boleto adquirido.
                                </li>
                            </ul>

                            {/* <p className="text-justify _pt-30" style={{fontSize:"20px"}}>
                                ¿Se ha cancelado su vuelo ?
                                <br />
                                Presione <b>"Siguiente" </b>y comience el proceso de su reembolso.
                                <br />


                                <br />
                                <span>
                                    Antes de solicitar un reembolso,
                                    revise <a className=" hover-Oscuro _c-p" target={'_Blank'} rel="nofollow noopener noreferrer" href={'https://www.avior.com.ve/tarifas'}>las condiciones de su boleto</a><br></br>
                                    Para acceder al reintegro de tu pago, aplicamos algunos cargos que representan la devolución parcial de tu tarifa.
                                </span>
                            </p> */}
                            <p>
                                {/*                                 
                              <small>
                              También descargue y llene el formato de la <a className=" hover-Oscuro _c-p" target={'_Blank'} rel="nofollow noopener noreferrer"  href={`${process.env.PUBLIC_URL}/Descargas/Carta Declaracion Jurada (Formato).pdf`}>declaración jurada</a> y si realizará una solicitud a un tercero deberá descargar el formato de la <a className=" hover-Oscuro _c-p" target={'_Blank'} rel="nofollow noopener noreferrer"  href={`${process.env.PUBLIC_URL}/Descargas/Carta de Autorización a Terceros (Formato).pdf`}>carta de autorización a terceros.</a> 
                                      </small>  */}
                            </p>
                            <br />
                            {/* <a className="seleccionable hover-Oscuro _c-p" href={'/'}>Volver </a> */}
                        </div>
                    </div>

                </div>
            </ div>
        </>
    );
}

export default Bienvenida;