import React, { useState, useEffect, Fragment } from 'react';
import MensajeAdvertencia from '../../Services/MensajeAdvertencia';
import LoaderScreen from '../layout/LoaderScreen';
import Moment from 'moment';
import { ReembolsoSevices } from '../../Services';
import swal from 'sweetalert';

const Paso4 = (props) => {
  const [Validar, setValidar] = useState(false);
  const [ActivarLoader, setActivarLoader] = useState(false);
  const [MensajeLoader, setMensajeLoader] = useState("");
  // AQUI VA EL CODIGO DE LA FORMA DE PAGO QUE SERÄ UTILIZADA PARA VALIDAR 
  // EL COMPORTAMIENTO DE TARJETA DE CREDITO
  const [FormaPagoC] = useState([2, 8]);
  const [PaisOrigen, setPaisOrigen] = useState("");
  const [PoseeBoleto, setPoseeBoleto] = useState("");
  var ListaPaises = [];
  var ListaDocumentoPorPais = [];
  const [DocIdentiDadTercero, setDocIdentiDadTercero] = useState("");
  const [AutorizacionTercero, setAutorizacionTercero] = useState("");
  const [DocIdentidad, setDocIdentidad] = useState("");
  const [CuentaBancaria, setCuentaBancaria] = useState("");
  const [Aba, SetAba] = useState("");
  const [Swift, setSwift] = useState("");

  const [DocIdentiDadTerceroExt, setDocIdentiDadTerceroExt] = useState("");
  const [AutorizacionTerceroExt, setAutorizacionTerceroExt] = useState("");
  const [DocIdentidadExt, setDocIdentidadExt] = useState("");
  const [CuentaBancariaExt, setCuentaBancariaExt] = useState("");

  const [DeclaracionJurada, setDeclaracionJurada] = useState("");
  const [DeclaracionJuradaExt, setDeclaracionJuradaExt] = useState("");

  const [ErrorNombresMsg, setErrorNombresMsg] = useState("");
  const [ErrorApellidosMsg, setErrorApellidosMsg] = useState("");

  const [Paises, setPaises] = useState([]);
  const [PasajeroDocumento, setPasajeroDocumento] = useState("");
  const [TipoDocumento, setTipoDocumento] = useState("");
  const [DocumentoPorPais, setDocumentoPorPais] = useState([]);
  const [MonedaNacional] = useState("VEF");
  const [MensajeExitoso, setMensajeExitoso] = useState(true);
  const [ExisteMoneda, setExisteMoneda] = useState(false);
  const [EsTitularCuenta, setEsTitularCuenta] = useState(true);
  const [DireccionPagador, setDireccionPagador] = useState("");
  const [HabilitarDocumentosReceptor, setHabilitarDocumentosReceptor] = useState(true);
  const [Nombres, setNombres] = useState("");
  const [Apellidos, setApellidos] = useState("");

  function solotexto(inputtxt) {
    var letters = /^[A-Za-z ]+$/;
    if (inputtxt.match(letters)) {
      return true;
    }
    else {
      return false;
    }

  }

  function asignarPasajeroDocumento(params) {
    if (params != "") {
      // setErrorApellidosMsg("");
      // if (solotexto(params)) {
      if (params.length <= 20) {
        //LOCALSTORAGE
        window.localStorage.setItem("ReceptorDocumento", params);
        setPasajeroDocumento(params);
      }
    }
    else {
      setPasajeroDocumento("");
      // setErrorApellidosMsg("- Campo obligatorio");
      window.localStorage.removeItem("ReceptorDocumento");
    }
  }

  function AsignarTipoDocumento(params) {
    if (params == "") {
      window.localStorage.removeItem("ReceptorTipoDocumentoSolicitante");
    } else {
      setTipoDocumento(params);
      window.localStorage.setItem("ReceptorTipoDocumentoSolicitante", params);
    }

  }

  function AsignarPaisOrigen(params) {
    if (params == "") {
      window.localStorage.removeItem("ReceptorPaisDocumento");
    } else {
      setPaisOrigen(params);
      ObtenerDocumentosPorPais(params);
      window.localStorage.setItem("ReceptorPaisDocumento", params);
    }
  }

  async function ObtenerDocumentosPorPais(Pais) {
    if (Pais == null) {

    } else {
      setActivarLoader(true);
      setMensajeLoader("Por favor espere...");
      const peticion = await ReembolsoSevices.ObtenerDocumentosPorPaises(Pais);
      if (peticion.status == 200) {
        if (peticion.resp != null) {
          setActivarLoader(false);
          setDocumentoPorPais(peticion.resp);
        }
      }
      else {
        // setErrorTipoPasajeroMsg("Ocurrió un error al cargar la lista de paises.");
      }
    }

  }

  async function ObtenerPaises() {
    const peticion = await ReembolsoSevices.ObtenerPaises();
    if (peticion.status == 200) {
      if (peticion.resp != null) {
        setPaises(peticion.resp);
      }
    }
    else {
      // setErrorTipoPasajeroMsg("Ocurrió un error al cargar la lista de paises.");
    }
  }



  function CuentaBancariaPasajero(params) {
    try {
      var Resultado = params.target.value;
      // alert(Resultado)
      window.localStorage.setItem("EsTitular", Resultado);

      if (Resultado == "true") {

        var PasajeroDocumento = window.localStorage.getItem("PasajeroDocumento");
        var TipoDocumentoSolicitante = window.localStorage.getItem("PasajeroTipoDocumentoSolicitante");
        var ReceptorPaisDocumento = window.localStorage.getItem("PasajeroPaisDocumento");
        var NombresReceptor = window.localStorage.getItem("Nombres");
        var ApellidosReceptor = window.localStorage.getItem("Apellidos");

        if (TipoDocumentoSolicitante != null) {
          window.localStorage.setItem("ReceptorTipoDocumentoSolicitante", TipoDocumentoSolicitante)
        } else {
          window.localStorage.removeItem("ReceptorTipoDocumentoSolicitante")
        }
        if (ReceptorPaisDocumento != null) {
          window.localStorage.setItem("ReceptorPaisDocumento", ReceptorPaisDocumento)
        } else {
          window.localStorage.removeItem("ReceptorPaisDocumento")
        }

        if (PasajeroDocumento != null) {
          window.localStorage.setItem("ReceptorDocumento", PasajeroDocumento)
        } else {
          window.localStorage.removeItem("ReceptorDocumento")
        }
        if (NombresReceptor != null) {
          window.localStorage.setItem("NombresReceptor", NombresReceptor)
        } else {
          window.localStorage.removeItem("NombresReceptor")
        }

        if (ApellidosReceptor != null) {
          window.localStorage.setItem("ApellidosReceptor", ApellidosReceptor)
        } else {
          window.localStorage.removeItem("ApellidosReceptor")
        }
        setEsTitularCuenta(true);
        setDocIdentiDadTercero("");
        setAutorizacionTerceroExt("")
        setAutorizacionTerceroExt("")
        setAutorizacionTerceroExt("")
        setDeclaracionJuradaExt("")
        setDeclaracionJuradaExt("")
        setHabilitarDocumentosReceptor(false);
        setApellidos(ApellidosReceptor);
        setNombres(NombresReceptor);
        setPaisOrigen(ReceptorPaisDocumento);
        setTipoDocumento(TipoDocumentoSolicitante);
        setPasajeroDocumento(PasajeroDocumento);

      }
      else {
        setEsTitularCuenta(false);
        setDocIdentiDadTercero("");
        setAutorizacionTerceroExt("")
        setAutorizacionTerceroExt("")
        setAutorizacionTerceroExt("")
        setDeclaracionJuradaExt("")
        setDeclaracionJuradaExt("")
        setHabilitarDocumentosReceptor(true);
        // window.localStorage.setItem("ReceptorTipoDocumentoSolicitante", "")
        // window.localStorage.setItem("ReceptorPaisDocumento", "")
        // window.localStorage.setItem("ReceptorDocumento", "")
        // window.localStorage.setItem("NombresReceptor", "")
        // window.localStorage.setItem("ApellidosReceptor", "")
        // window.localStorage.removeItem("PasajeroDocumento");
        // window.localStorage.removeItem("ReceptorTipoDocumentoSolicitante");
        // window.localStorage.removeItem("ReceptorPaisDocumento");
        // window.localStorage.removeItem("ApellidosReceptor");
        // window.localStorage.removeItem("NombresReceptor");
        setPasajeroDocumento("");
        setTipoDocumento("");
        setPaisOrigen("");
        setApellidos("");
        setNombres("");
      }
    } catch (error) {
      // alert(Resultado)
      window.localStorage.setItem("EsTitular", false);
      var PasajeroDocumento = window.localStorage.getItem("PasajeroDocumento");
      var TipoDocumentoSolicitante = window.localStorage.getItem("PasajeroTipoDocumentoSolicitante");
      var ReceptorPaisDocumento = window.localStorage.getItem("PasajeroPaisDocumento");
      var NombresReceptor = window.localStorage.getItem("Nombres");
      var ApellidosReceptor = window.localStorage.getItem("Apellidos");

      if (TipoDocumentoSolicitante != null) {
        window.localStorage.setItem("ReceptorTipoDocumentoSolicitante", TipoDocumentoSolicitante)
      } else {
        window.localStorage.removeItem("ReceptorTipoDocumentoSolicitante")
      }
      if (ReceptorPaisDocumento != null) {
        window.localStorage.setItem("ReceptorPaisDocumento", ReceptorPaisDocumento)
      } else {
        window.localStorage.removeItem("ReceptorPaisDocumento")
      }

      if (PasajeroDocumento != null) {
        window.localStorage.setItem("ReceptorDocumento", PasajeroDocumento)
      } else {
        window.localStorage.removeItem("ReceptorDocumento")
      }
      if (NombresReceptor != null) {
        window.localStorage.setItem("NombresReceptor", NombresReceptor)
      } else {
        window.localStorage.removeItem("NombresReceptor")
      }

      if (ApellidosReceptor != null) {
        window.localStorage.setItem("ApellidosReceptor", ApellidosReceptor)
      } else {
        window.localStorage.removeItem("ApellidosReceptor")
      }
      setHabilitarDocumentosReceptor(true);
      setEsTitularCuenta(false);
      setDocIdentiDadTercero("");
      setAutorizacionTerceroExt("")
      setAutorizacionTerceroExt("")
      setAutorizacionTerceroExt("")
      setDeclaracionJuradaExt("")
      setDeclaracionJuradaExt("")

    }

  }

  function asignarNombres(params) {
    if (params != "") {
      setErrorNombresMsg("");
      if (solotexto(params)) {
        //LOCALSTORAGE
        if (params.length <= 50) {
          window.localStorage.setItem("NombresReceptor", params);
          setNombres(params);
        }
      }
      else {
        setErrorNombresMsg("Debe ingresar solo texto");
      }
    }
    else {
      setNombres("");
      window.localStorage.removeItem("NombresReceptor");
      setErrorNombresMsg("- Campo obligatorio");
    }
    // CamposObligatoriosCorrectos();
  }

  function asignarApellidos(params) {
    if (params != "") {
      setErrorApellidosMsg("");
      if (solotexto(params)) {
        if (params.length <= 50) {
          //LOCALSTORAGE
          window.localStorage.setItem("ApellidosReceptor", params);
          setApellidos(params);
        }
      }
      else {
        window.localStorage.removeItem("ApellidosReceptor");
        setErrorApellidosMsg("Debe ingresar solo texto");
      }
    }
    else {
      setApellidos("");
      setErrorApellidosMsg("- Campo obligatorio");
      window.localStorage.removeItem("ApellidosReceptor");
    }
    // CamposObligatoriosCorrectos();
  }

  function SeHaseleccionadoMoneda() {
    try {
      var Moneda = window.localStorage.getItem("Moneda");
      var PaymentMethodID = window.localStorage.getItem("FormaPago");

      if (Moneda != null) {
        //VALIDAR SI LA MONEDA ES BOLIVAR SOBERANO
        if (Moneda != MonedaNacional) {
          if (PaymentMethodID !== "5") {
            if (PaymentMethodID !== "2") {
              setExisteMoneda(true);
            }
          }
        }
      }
      // if (PaymentMethodID != "2" || PaymentMethodID != "5") {
      //   setExisteMoneda(false);
      // }
    } catch (error) {
    }
  }

  function AsignarDireccionPagador(params) {
    if (params == "") {
      setDireccionPagador(params);
      window.localStorage.removeItem("DireccionPagador");
    } else {
      if (params.length > 10) {
        window.localStorage.setItem("DireccionPagador", params);
        setDireccionPagador(params);
      } else {
        setDireccionPagador(params);
        window.localStorage.removeItem("DireccionPagador");
      }
    }
  }

  async function ProcesarReembolso() {
    try {

      var DireccionPagador = window.localStorage.getItem("DireccionPagador");
      var esPaypal = false; // Indica si es paypal o TC; Si es Sí, entonces omitimos la valdciones de la pagina 4
      var PaymentMethodID = window.localStorage.getItem("FormaPago");
      var EsTitular = window.localStorage.getItem("EsTitular");
      var CurrencyID = window.localStorage.getItem("Moneda");
      var EsPasajero = 0;
      var ReceptorPaisDocumento = window.localStorage.getItem("ReceptorPaisDocumento");
      var TipoDocumentoSolicitante = window.localStorage.getItem("ReceptorTipoDocumentoSolicitante");
      var PasajeroDocumento = window.localStorage.getItem("ReceptorDocumento");
      var ReasonID = window.localStorage.getItem("Motivo");
      var Comment = window.localStorage.getItem("Observaciones");
      var numTicket = window.localStorage.getItem("Boleto");
      var DateBoleto = window.localStorage.getItem("FechaBoleto");
      var Origin = window.localStorage.getItem("Origen");
      var Destination = window.localStorage.getItem("Destino");
      var NamePAX = window.localStorage.getItem("Nombres");
      var LastNamePAX = window.localStorage.getItem("Apellidos");
      var TipoPasajero = window.localStorage.getItem("TipoPasajero");
      var Correo = window.localStorage.getItem("Correo");
      var NombresReceptor = window.localStorage.getItem("NombresReceptor");
      var ApellidosReceptor = window.localStorage.getItem("ApellidosReceptor");
      var Aba = window.localStorage.getItem("Aba");
      var Swift = window.localStorage.getItem("Swift");
      var PoseeBoleto = window.localStorage.getItem("PoseeBoleto");
      var PaymentMethodID = window.localStorage.getItem("FormaPago");
      // 0 - Recorrer las formas de pago que serán directas
      for (let index = 0; index < FormaPagoC.length; index++) {

        const element = FormaPagoC[index];
        // Es paypal o TC
        if (PaymentMethodID.toString() == element.toString()) {
          esPaypal = true;
        }
      }

      // 1 - Asignaciones predeterminadas
      if (EsTitular == "true") {
        EsPasajero = 1;

      } else {
        EsPasajero = 0;
      }
      var Boleto = 0;

      if (PoseeBoleto == "S") {
        Boleto = 0;
      }
      else {
        Boleto = 1;
      }

      // VAlidar datos del boleto 
      if (CurrencyID == null) {
        MensajeAdvertencia("Advertencia", "En algún momento del proceso, se perdío la moneda. Reinicie el proceso nuevamente.", "warning", "Ok");
        return;
      }
      // console.log("Moneda validada")
      // Validar metodo de pago
      if (PaymentMethodID == null) {
        MensajeAdvertencia("Advertencia", "En algún momento del proceso, se perdío el método de pago. Reinicie el proceso nuevamente.", "warning", "Ok");
        return;
      }
      // console.log("Metodo de pago validada")
      // Validar correo electronico
      if (Correo == null) {
        MensajeAdvertencia("Advertencia", "En algún momento del proceso, se perdío el correo del solicitante. Reinicie el proceso nuevamente.", "warning", "Ok");
        return;
      }
      // console.log("Correo validada")
      // Si posee boletos, debe validar y asignar : 
      if (Boleto == 1) {
        // Nombre del pasajero
        if (numTicket == null) {
          MensajeAdvertencia("Advertencia", "En algún momento del proceso, se perdío el número de boleto. Reinicie el proceso nuevamente.", "warning", "Ok");
          return;
        }
        // console.log("Ticket validada")
        // Nombre del pasajero
        if (NamePAX == null) {
          MensajeAdvertencia("Advertencia", "En algún momento del proceso, se perdío el nombre del solicitante. Reinicie el proceso nuevamente.", "warning", "Ok");
          return;
        }
        // console.log("Nombre pasajero validada")
        // Apellido del pasaero
        if (LastNamePAX == null) {
          MensajeAdvertencia("Advertencia", "En algún momento del proceso, se perdío el apellido del solicitante. Reinicie el proceso nuevamente.", "warning", "Ok");
          return;
        }
        // console.log("Apellido pasajero validada")
        // Fecha de boleto, si esta vacía..
        if (DateBoleto == null) {
          MensajeAdvertencia("Advertencia", "En algún momento del proceso, se perdío la fecha de emisión del boleto. Reinicie el proceso nuevamente.", "warning", "Ok");
          return;
        }
        // console.log("Fecha de boleto validada")
        // Fecha de boleto, si esta vacía..
        if (Origin == null) {
          MensajeAdvertencia("Advertencia", "En algún momento del proceso, se perdío el origen del boleto. Reinicie el proceso nuevamente.", "warning", "Ok");
          return;
        }
        // console.log("Origen de boleto validada")
        // Fecha de boleto, si esta vacía..
        if (Destination == null) {
          MensajeAdvertencia("Advertencia", "En algún momento del proceso, se perdío el destino del boleto. Reinicie el proceso nuevamente.", "warning", "Ok");
          return;
        }
        // console.log("Destino de boleto validada")
        if (TipoPasajero == null) {
          MensajeAdvertencia("Advertencia", "En algún momento del proceso, se perdío el tipo de pasajero. Reinicie el proceso nuevamente.", "warning", "Ok");
          return;
        }
        // console.log("Tipo pasajero validada")

      }
      // console.log("Posee Boleto" + Boleto);

      // 3 - Validaciones del paso 4
      if (!esPaypal) {
        // Validar paso 4 i

        // Datos del receptor
        // ------------------------
        //Nombre del receptor
        if (NombresReceptor == null) {
          MensajeAdvertencia("Advertencia", "Debe indicar el nombre del receptor.", "warning", "Ok");
          return;
        }
        // console.log("Nombre receptoro validada")
        // Apellido del receptor
        if (ApellidosReceptor == null) {
          MensajeAdvertencia("Advertencia", "Debe indicar el apellido del receptor.", "warning", "Ok");
          return;
        }
        // console.log("Apellido receptoro validada")
        // Pais Origen dle edocumento 
        if (ReceptorPaisDocumento == null) {
          MensajeAdvertencia("Advertencia", "Debe seleccionar el origen del documento del receptor.", "warning", "Ok");
          return;
        }
        // console.log("Pais documento receptoro validada")
        // Tipo de documento
        if (TipoDocumentoSolicitante == null) {
          MensajeAdvertencia("Advertencia", "Debe indicar el tipo de documento del receptor.", "warning", "Ok");
          return;
        }
        // console.log("Tipo documento receptoro validada")
        if (PasajeroDocumento == null) {
          MensajeAdvertencia("Advertencia", "Debe indicar el documento del receptor.", "warning", "Ok");
          return;
        }
        // console.log("Focumento receptoro validada")
        if (DireccionPagador == null) {
          MensajeAdvertencia("Advertencia", "Debe indicar la dirección del titular de la cuenta.", "warning", "Ok");
          return;
        } else {
          if (DireccionPagador.length < 10) {
            MensajeAdvertencia("Advertencia", "Debe indicar una dirección válida.", "warning", "Ok");
            return;
          }
        }
        // console.log("Direccion del pagador")

        if (PaymentMethodID != "5") {
          if (CurrencyID != MonedaNacional) {
            if (Aba == null && Swift == null) {
              MensajeAdvertencia("Advertencia", "Debe indicar el código ABA o el código SWIFT de su banco internacional.", "warning", "Ok");
              return;
            }
          }
        }
        //alert(EsPasajero)
        if (EsPasajero == 1) {
          // PEDIR DOCUMENTOS DEL TITULAR
          if (DocIdentidad == null || DocIdentidad == "") {
            MensajeAdvertencia("Advertencia", "Debe adjuntar un soporte en formato PDF/JPG de su documento de identidad.", "warning", "Ok");
            return;
          }
          // console.log("Adjunto Doc Identidad validada")
          if (CuentaBancaria == null || CuentaBancaria == "") {
            MensajeAdvertencia("Advertencia", "Debe adjuntar en formato PDF/JPG un soporte de su cuenta bancaria.", "warning", "Ok");
            return;
          }
          // console.log("Adjunto Cuenta bancaria validada")
          if (DeclaracionJurada == null || DeclaracionJurada == "") {
            MensajeAdvertencia("Advertencia", "Debe adjuntar un soporte en formato PDF/JPG de la declaración jurada.", "warning", "Ok");
            return;
          }

          // console.log("Es titular")
        } else {

          if (DocIdentiDadTercero == null || DocIdentiDadTercero == "") {
            MensajeAdvertencia("Advertencia", "Debe adjuntar en formato PDF/JPG un soporte del documento de identidad del tercero.", "warning", "Ok");
            return;
          }
          // console.log("Adjunto Doc IDentidad tercero validada")
          if (AutorizacionTercero == null || AutorizacionTercero == "") {
            MensajeAdvertencia("Advertencia", "Debe adjuntar en formato PDF/JPG un soporte de la autorización del tercero.", "warning", "Ok");
            return;
          }
          // console.log("Adjunto  cuenta bancaria tercero validada")
          if (CuentaBancaria == null || CuentaBancaria == "") {
            MensajeAdvertencia("Advertencia", "Debe adjuntar en formato PDF/JPG un soporte de la cuenta bancaría del tercero.", "warning", "Ok");
            return;
          }
          // console.log("AdjuntCuenta bancaria d  validada")
          if (DocIdentidad == null || DocIdentidad == "") {
            MensajeAdvertencia("Advertencia", "Debe adjuntar un soporte en formato PDF/JPG de su documento de identidad.", "warning", "Ok");
            return;
          }
          // console.log("Adjunto Doc Identidad  validada")
          if (DeclaracionJurada == null || DeclaracionJurada == "") {
            MensajeAdvertencia("Advertencia", "Debe adjuntar un soporte en formato PDF/JPG de la declaración jurada.", "warning", "Ok");
            return;
          }
          // console.log("Adjunto Declaracion jurada validada")
        }
      }
      else {
        // es paypal o TC
        if (NombresReceptor == null) {
          NombresReceptor = " - ";
        }
        // console.log("Nombre receptoro validada")
        // Apellido del receptor
        if (ApellidosReceptor == null) {
          ApellidosReceptor = " - ";
        }
        if (DireccionPagador == null) {
          DireccionPagador = " - ";
        }
        if (LastNamePAX == null) {
          LastNamePAX = " - ";
        }
        if (NamePAX == null) {
          NamePAX = " - ";
        }

        if (Aba == null) {
          Aba = " - ";
        }
        if (Swift == null) {
          Swift = " - ";
        }
        if (ReceptorPaisDocumento == null) {
          ReceptorPaisDocumento = " - ";
        }
        if (TipoDocumentoSolicitante == null) {
          TipoDocumentoSolicitante = 1;
        }
        if (PasajeroDocumento == null) {
          PasajeroDocumento = " - ";
        }


      }
      var Request = {
        "isClaim": "0",
        "ClaimDetail": {
          "DateReception": Moment(),
          "DateClosed": null,
          "Observation": "",
          "Support": {
            "ID": "sample string 1",
            "Description": "sample string 2",
            "URL": "sample string 3",
            "Message": "sample string 4",
            "isRequired": true,
            "File": "QEA=",
            "FileExt": "sample string 6"
          }
        },
        "isComplaint": "0",
        "ComplaintDetail": {
          "nroComplaint": "",
          "Institution": "",
          "Date": "2020-11-13T09:18:50.8746357-04:00",
          "DateReception": "2020-11-13T09:18:50.8902612-04:00",
          "DateClosed": "2020-11-13T09:18:50.8902612-04:00",
          "Observation": "sample string 7"
        },
        "withTicket": Boleto,
        "TicketDetail": {
          "numTicket": numTicket,
          "Date": Moment(DateBoleto),
          "Origin": Origin,
          "Destination": Destination,
          "NamePAX": NamePAX,
          "LastNamePAX": LastNamePAX,
          "TypePAX": TipoPasajero
        },
        "CurrencyID": CurrencyID,
        "ReasonID": ReasonID,
        "ServiceCenterID": null,
        "Comment": Comment,
        "RefundReference": null,
        "Payers": [
          {
            "Name": NamePAX,
            "LastName": LastNamePAX,
            "Email": Correo,
            "PaymentMethodID": PaymentMethodID,
            "Supports": [
              {
                "ID": 17,
                "Description": "Autorización de tercero",
                "File": (AutorizacionTercero),
                "FileExt": AutorizacionTerceroExt
              },
              {
                "ID": 1,
                "Description": "Identidad de tercero",
                "File": (DocIdentiDadTercero),
                "FileExt": DocIdentiDadTerceroExt
              },
              {
                "ID": 19,
                "Description": "Documento de identidad",
                "File": (DocIdentidad),
                "FileExt": DocIdentidadExt
              },
              {
                "ID": 3,
                "Description": "Cuenta bancaria",
                "File": (CuentaBancaria),
                "FileExt": CuentaBancariaExt
              },
              {
                "ID": 20,
                "Description": "Declaración jurada",
                "File": (DeclaracionJurada),
                "FileExt": DeclaracionJuradaExt
              },
            ]
          }
        ],
        "Receiver": {
          "isPassenger": EsPasajero,
          "Name": NombresReceptor,
          "LastName": ApellidosReceptor,
          "Nacionality": "",
          "DOCCountry": ReceptorPaisDocumento,
          "DOCType": TipoDocumentoSolicitante,
          "DOCNumber": PasajeroDocumento,
          "Phone": "",
          "Email": Correo,
          "Address": DireccionPagador,
          "Bank": "",
          "CardType": "",
          "CardNumber": "",
          "CardName": "",
          "CardDateExp": "",
          "CardDateDue": "",
          "AccountType": "",
          "AccountNum": "",
          "Aba": Aba,
          "Swift": Swift,
          "ZidCode": "",
          "Currency": CurrencyID,
          "Supports": [
            {
              "ID": 17,
              "Description": "Autorización de tercero",
              "File": (AutorizacionTercero),
              "FileExt": AutorizacionTerceroExt
            },
            {
              "ID": 1,
              "Description": "Identidad de tercero",
              "File": (DocIdentiDadTercero),
              "FileExt": DocIdentiDadTerceroExt
            },
            {
              "ID": 19,
              "Description": "Documento de identidad",
              "File": (DocIdentidad),
              "FileExt": DocIdentidadExt
            },
            {
              "ID": 3,
              "Description": "Cuenta bancaria",
              "File": (CuentaBancaria),
              "FileExt": CuentaBancariaExt
            },
            {
              "ID": 20,
              "Description": "Declaración jurada",
              "File": (DeclaracionJurada),
              "FileExt": DeclaracionJuradaExt
            },
          ]
        }
      }

      console.log(JSON.stringify(Request))
      setActivarLoader(true);
      setMensajeLoader("Procesando reembolso...");
      const peticion = await ReembolsoSevices.ProcesarReembolso(JSON.stringify(Request));
      // alert(JSON.stringify(peticion))
      if (peticion.status == 200) {
        setActivarLoader(false);

        
        if (!peticion.resp.includes("ERROR")) {

          swal({
            title: "Solicitud generada con éxito",
            text: "Hemos registrado su requerimiento de reembolso " + peticion.resp + ", Este número de identificación ha sido enviado  a su correo electrónico. Con el podrá consultar el estado de su solicitud en nuestra página web o en cualquier oficina comercial de Avior Airlines.",
            icon: "success",
            buttons: {
                cancel: "Solicitar otro reembolso",
                catch: {
                  text: "Salir",
                  value: "catch",
                },
              }
          })
            .then((value) => {
              switch (value) {
               
                case "catch":
                  window.localStorage.clear();
                  window.location.href = "https://aviorair.com";
                  break;
                default:
                  window.localStorage.clear();
                  window.location.href = "/solicitar";
              }
            
            });
        }
        else {
          var FormaPago = window.localStorage.getItem("FormaPago");
          for (let index = 0; index < FormaPago.length; index++) {
            const element = FormaPago[index];
            // Es paypal o TC
            if (FormaPago.toString() == element.toString()) {
              document.querySelector('._3CDiP').click();
            }
          }
          swal({
            title: "Advertencia",
            text: "Ocurrió un error al realizar la solicitud del reembolso,",
            icon: "warning",
            buttons: {
              cancel: "Salir",
              catch: {
                text: "Revisar y reintentar",
                value: "catch",
              },
              defeat: false,
            },
          })
            .then((value) => {
              switch (value) {
                case "catch":
                  break;
                default:
                  window.localStorage.clear();
                  window.location.href = "/";
              }
            });



          // MensajeAdvertencia("Advertencia", "Ocurrió un error al realizar la solicitud del reembolso, intente nuevamente. Detalles: " + peticion.status + " , " + peticion.resp, "warning", "Reintentar");

        }
      }
    } catch (error) {
      MensajeAdvertencia("Advertencia", "Ocurió un error: " + error, "warning", "Reintentar");
      return;
    }

  }

  function AsignarSwift(params) {
    setSwift(params);
    if (params == "") {
      window.localStorage.removeItem("Swift");
    } else {
      window.localStorage.setItem("Swift", params);
    }
  }

  function AsignarAba(params) {
    SetAba(params);

    if (params == "") {
      window.localStorage.removeItem("Aba");
    } else {
      window.localStorage.setItem("Aba", params);
    }
  }

  async function AsignarIdentidadTercero(params) {
    try {
      if (params) {
        var fileName = params.name;
        var Size = params.size / 1024 / 1024; // in MB;
        if (Size > 0.5) {
          MensajeAdvertencia("Advertencia", "El soporte no debe superar un tamaño de 500Kb.", "warning", "Reintentar");
          return;
        } else {

        }
        var fileExtension = fileName.split('.').pop();
        setDocIdentiDadTerceroExt(fileExtension)
        const reader = new FileReader();
        reader.onload = _hanldleReaderLoadedIDTerce.bind(this);
        reader.readAsBinaryString(params);
      }

    } catch (error) {
      MensajeAdvertencia("Advertencia", "Ocurió un error: " + error, "warning", "Reintentar");
      setDocIdentiDadTercero("")
    }

  }

  function AsignarAutorizacionTercero(params) {
    try {
      if (params) {
        // console.log(params)
        var fileName = params.name;
        //Get the file Extension 
        var fileExtension = fileName.split('.').pop();
        var Size = params.size / 1024 / 1024; // in MB;
        if (Size > 0.5) {
          MensajeAdvertencia("Advertencia", "El soporte no debe superar un tamaño de 500Kb.", "warning", "Reintentar");
          return;
        } else {
          setAutorizacionTerceroExt(fileExtension)
          const reader = new FileReader();
          reader.onload = _hanldleReaderLoadedAutoriTercer.bind(this);
          reader.readAsBinaryString(params);
        }
      }
    } catch (error) {
      MensajeAdvertencia("Advertencia", "Ocurió un error: " + error, "warning", "Reintentar");
      setAutorizacionTercero("")
    }
  }

  function AsignarDocumentoIDentidad(params) {
    try {

      if (params) {
        // console.log(params)
        var fileName = params.name;
        //Get the file Extension 
        var Size = params.size / 1024 / 1024; // in MB;
        if (Size > 0.5) {
          MensajeAdvertencia("Advertencia", "El soporte no debe superar un tamaño de 500Kb.", "warning", "Reintentar");
          return;
        } else {
          var fileExtension = fileName.split('.').pop();
          setDocIdentidadExt(fileExtension)
          const reader = new FileReader();
          reader.onload = _hanldleReaderLoadedIDentidad.bind(this);
          reader.readAsBinaryString(params);
        }
      }
    } catch (error) {
      MensajeAdvertencia("Advertencia", "Ocurió un error: " + error, "warning", "Reintentar");
      setDocIdentidad("");
    }
  }

  function ASignarDelcaracionJurada(params) {
    try {
      if (params) {
        // console.log(params)
        var fileName = params.name;
        //Get the file Extension 
        var Size = params.size / 1024 / 1024; // in MB;
        if (Size > 0.5) {
          MensajeAdvertencia("Advertencia", "El soporte no debe superar un tamaño de 500Kb.", "warning", "Reintentar");
          return;
        } else {
          var fileExtension = fileName.split('.').pop();
          setDeclaracionJuradaExt(fileExtension)


          const reader = new FileReader();
          reader.onload = _hanldleReaderLoadedDeclaracionJurada.bind(this);
          reader.readAsBinaryString(params);
        }
      }
    } catch (error) {
      MensajeAdvertencia("Advertencia", "Ocurió un error: " + error, "warning", "Reintentar");
      setDeclaracionJurada("");
    }
  }

  function AsignarDocumentoIDentidad2(params) {
    try {
      if (params) {
        // console.log(params)
        var fileName = params.name;
        //Get the file Extension 
        var Size = params.size / 1024 / 1024; // in MB;
        if (Size > 0.5) {
          MensajeAdvertencia("Advertencia", "El soporte no debe superar un tamaño de 500Kb.", "warning", "Reintentar");
          return;
        } else {
          var fileExtension = fileName.split('.').pop();
          setDocIdentidadExt(fileExtension)

          const reader = new FileReader();
          reader.onload = _hanldleReaderLoadedIDentidad2.bind(this);
          reader.readAsBinaryString(params);
        }
      }
    } catch (error) {
      MensajeAdvertencia("Advertencia", "Ocurió un error: " + error, "warning", "Reintentar");
      setDocIdentidadExt("");
    }
  }

  function AsignarCuentaBancaria(params) {
    try {
      if (params) {
        var fileName = params.name;
        var Size = params.size / 1024 / 1024; // in MB;
        //Get the file Extension 
        if (Size > 0.5) {
          MensajeAdvertencia("Advertencia", "El soporte no debe superar un tamaño de 500Kb.", "warning", "Reintentar");
          return;
        } else {
          var fileExtension = fileName.split('.').pop();
          setCuentaBancariaExt(fileExtension)

          const reader = new FileReader();
          reader.onload = _hanldleReaderLoadedCuentaBanc.bind(this);
          reader.readAsBinaryString(params);
        }
      }
    } catch (error) {
      MensajeAdvertencia("Advertencia", "Ocurió un error: " + error, "warning", "Reintentar");
      setCuentaBancaria("");
    }
  }

  function _hanldleReaderLoadedIDTerce(readerEvt) {
    let BinaryString = readerEvt.target.result;
    setDocIdentiDadTercero(btoa(BinaryString))
  }
  function _hanldleReaderLoadedAutoriTercer(readerEvt) {
    let BinaryString = readerEvt.target.result;
    setAutorizacionTercero(btoa(BinaryString))
  }
  function _hanldleReaderLoadedDeclaracionJurada(readerEvt) {
    let BinaryString = readerEvt.target.result;
    setDeclaracionJurada(btoa(BinaryString))
    // console.log(btoa(BinaryString))
  }
  function _hanldleReaderLoadedIDentidad(readerEvt) {
    let BinaryString = readerEvt.target.result;
    setDocIdentidad(btoa(BinaryString))
  }
  function _hanldleReaderLoadedIDentidad2(readerEvt) {
    let BinaryString = readerEvt.target.result;
    setDocIdentidad(btoa(BinaryString))
  }
  function _hanldleReaderLoadedCuentaBanc(readerEvt) {
    let BinaryString = readerEvt.target.result;
    setCuentaBancaria(btoa(BinaryString))
  }

  useEffect(() => {
    

    // validartarjetaCredito();
    // window.localStorage.removeItem("DireccionPagador");
    // window.localStorage.setItem("EsTitular", false);
    var FlagActiva = false;
    var FormaPagoLocalS = window.localStorage.getItem("FormaPago");

    for (let index = 0; index < FormaPagoC.length; index++) {
      const element = FormaPagoC[index];
      // Es paypal o TC
      if (FormaPagoLocalS.toString() == element.toString()) {
        FlagActiva = true;
      }
      if (index == (FormaPagoC.length - 1)) {
        if (FlagActiva == true) {
          document.getElementsByClassName('_3uApM ')[0].style.display = 'flex';
          document.querySelector('._3CDiP').click();
          ProcesarReembolso();
        } else {
          document.getElementsByClassName('_3uApM ')[0].style.display = 'none';
          ValidarCuenta();
          ObtenerPaises();
          ObtenerReceptor();
          SeHaseleccionadoMoneda();
        }
      }
    }


  }, []);

  function ValidarCuenta() {
    try {

      var PoseeBoleto = window.localStorage.getItem("PoseeBoleto");
      if (PoseeBoleto == "S") {
        setPoseeBoleto(false);
        CuentaBancariaPasajero(false);
        window.localStorage.setItem("EsTitular", false);
      } else {
        setPoseeBoleto(true);
        window.localStorage.setItem("EsTitular", true);
      }
    } catch (error) {
      MensajeAdvertencia("Advertencia", "Ocurió un error: " + error, "warning", "Reintentar");
    }
  }

  function ObtenerReceptor() {
    try {

      var PasajeroDocumento = window.localStorage.getItem("PasajeroDocumento");
      var TipoDocumentoSolicitante = window.localStorage.getItem("PasajeroTipoDocumentoSolicitante");
      var ReceptorPaisDocumento = window.localStorage.getItem("PasajeroPaisDocumento");
      var NombresReceptor = window.localStorage.getItem("Nombres");
      var ApellidosReceptor = window.localStorage.getItem("Apellidos");

      if (TipoDocumentoSolicitante != null) {
        window.localStorage.setItem("ReceptorTipoDocumentoSolicitante", TipoDocumentoSolicitante)
      } else {
        window.localStorage.removeItem("ReceptorTipoDocumentoSolicitante")
      }
      if (ReceptorPaisDocumento != null) {
        window.localStorage.setItem("ReceptorPaisDocumento", ReceptorPaisDocumento)
      } else {
        window.localStorage.removeItem("ReceptorPaisDocumento")
      }

      if (PasajeroDocumento != null) {
        window.localStorage.setItem("ReceptorDocumento", PasajeroDocumento)
      } else {
        window.localStorage.removeItem("ReceptorDocumento")
      }
      if (NombresReceptor != null) {
        window.localStorage.setItem("NombresReceptor", NombresReceptor)
      } else {
        window.localStorage.removeItem("NombresReceptor")
      }

      if (ApellidosReceptor != null) {
        window.localStorage.setItem("ApellidosReceptor", ApellidosReceptor)
      } else {
        window.localStorage.removeItem("ApellidosReceptor")
      }


      setNombres(NombresReceptor);
      setApellidos(ApellidosReceptor);

      if (ReceptorPaisDocumento !== null) {
        ObtenerDocumentosPorPais(ReceptorPaisDocumento);
      }
      setPasajeroDocumento(PasajeroDocumento);
      setTipoDocumento(TipoDocumentoSolicitante);
      setPaisOrigen(ReceptorPaisDocumento);
      if (PasajeroDocumento == null || ReceptorPaisDocumento == null || ReceptorPaisDocumento == null) {

        setHabilitarDocumentosReceptor(true);


      } else {
        setHabilitarDocumentosReceptor(false);

      }
    } catch (error) {
      MensajeAdvertencia("Advertencia", "Ocurió un error: " + error, "warning", "Reintentar");
    }
  }


  return (
    <>
      <LoaderScreen props={ActivarLoader} mensaje={MensajeLoader} />
      <div className="_pt-30">
        <div class="theme-page-section-header _ta-l">
          <h5 class="theme-page-section-title">Consignación de documentos </h5>
          <h5 className="feature-subtitle _op-06">Todos los campos habilitados y marcados con asteriscos (*) son obligatorios.</h5>
          <hr className=" style-3"></hr>
        </div>
        <div class="row row-col-gap " data-gutter="20">
          <div class="col-md-12 ">
            <h5 className=" _c-dark"><span className="_c-p">*</span> ¿La cuenta bancaria pertenece al pasajero?</h5>
            <div class="theme-payment-page-form-item form-group">
              <div class="theme-account-notifications-item seleccionable">
                <div onChange={CuentaBancariaPasajero.bind(this)}>
                  {/* {!PoseeBoleto ? (<> */}
                  <input type="radio" value={false} name="pertenece" defaultChecked={true} /> No<br />
                  <input type="radio" value={true} name="pertenece" /> Si
                  {/* </>) :
                    <>
                      <input type="radio" value={true} name="pertenece" /> Si<br />
                      <input type="radio" value={false} name="pertenece" /> No

                    </>} */}

                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div class="theme-page-section-header _ta-l _pt-20">
              <h5 class="theme-page-section-title" style={{ fontSize: "28px" }}>Receptor</h5>
              {PoseeBoleto ? (<> <h5 className="feature-subtitle _op-06">Datos del documento de la persona que recibirá el reembolso.</h5></>) : <> <h5 className="feature-subtitle _op-06">Ingrese los datos del documento de la persona que recibirá el reembolso.</h5></>}


              <hr className=" style-3"></hr>
            </div>
          </div>
          <div class="col-md-6 ">
            <h5 className=" _c-dark"><span className="_c-p">*</span>  Nombre(s) del receptor  <span className=" _c-p">{ErrorNombresMsg}</span></h5>
            <div class="theme-payment-page-form-item form-group">
              {!HabilitarDocumentosReceptor ? (<>
                <input
                  value={Nombres}
                  onChange={(ev) => asignarNombres(ev.target.value)}
                  class="form-control"
                  type="text"
                  placeholder="Nombre(s)"

                />
              </>) : <>
                <input
                  value={Nombres}
                  onChange={(ev) => asignarNombres(ev.target.value)}
                  class="form-control"
                  type="text"
                  placeholder="Nombre(s)"

                />
              </>}


            </div>
          </div>
          <div class="col-md-6 ">
            <h5 className=" _c-dark"><span className="_c-p">*</span>  Apellido(s) del receptor  <span className=" _c-p">{ErrorApellidosMsg}</span></h5>
            <div class="theme-payment-page-form-item form-group">
              {!HabilitarDocumentosReceptor ? (<>
                <input
                  value={Apellidos}
                  onChange={(ev) => asignarApellidos(ev.target.value)}
                  class="form-control"
                  type="text"
                  placeholder="Apellido(s)"

                />
              </>) : <>
                <input
                  value={Apellidos}
                  onChange={(ev) => asignarApellidos(ev.target.value)}
                  class="form-control"
                  type="text"
                  placeholder="Apellido(s)"
                />
              </>}


            </div>
          </div>
          <div class="col-md-4 ">
            <h5 className=" _c-dark"><span className="_c-p">*</span>  Origen del documento  <span className=" _c-p"></span></h5>
            <div class="theme-payment-page-form-item form-group">
              <i class="fa fa-angle-down"></i>
              {!HabilitarDocumentosReceptor ? (<>
                <select class="form-control seleccionable"
                  onChange={(ev) => AsignarPaisOrigen(ev.target.value)}
                  value={PaisOrigen}

                >
                  <option value={""}>Seleccione</option>
                  {Object.keys(Paises).forEach(valor => {
                    var Objeto = Paises[valor];
                    ListaPaises.push(
                      <option value={Objeto.ID}>{Objeto.Description}</option>
                    );
                  })}
                  {ListaPaises}
                </select>
              </>) : <>
                <select class="form-control seleccionable"
                  onChange={(ev) => AsignarPaisOrigen(ev.target.value)}
                  value={PaisOrigen}
                >
                  <option value={""}>Seleccione</option>
                  {Object.keys(Paises).forEach(valor => {
                    var Objeto = Paises[valor];
                    ListaPaises.push(
                      <option value={Objeto.ID}>{Objeto.Description}</option>
                    );
                  })}
                  {ListaPaises}
                </select>
              </>}


            </div>
          </div>


          <div class="col-md-4 ">
            <h5 className=" _c-dark"><span className="_c-p">*</span>  Tipo de documento  <span className=" _c-p"></span></h5>
            <div class="theme-payment-page-form-item form-group">
              <i class="fa fa-angle-down"></i>
              {!HabilitarDocumentosReceptor ? (<>
                <select class="form-control seleccionable"
                  onChange={(ev) => AsignarTipoDocumento(ev.target.value)}
                  value={TipoDocumento}

                >
                  <option value={""}>Seleccione</option>
                  {Object.keys(DocumentoPorPais).forEach(valor => {
                    var Objeto = DocumentoPorPais[valor];
                    ListaDocumentoPorPais.push(
                      <option value={Objeto.ID}>{Objeto.Description}</option>
                    );
                  })}
                  {ListaDocumentoPorPais}
                </select>
              </>) : <>
                <select class="form-control seleccionable"
                  onChange={(ev) => AsignarTipoDocumento(ev.target.value)}
                  value={TipoDocumento}
                >
                  <option value={""}>Seleccione</option>
                  {Object.keys(DocumentoPorPais).forEach(valor => {
                    var Objeto = DocumentoPorPais[valor];
                    ListaDocumentoPorPais.push(
                      <option value={Objeto.ID}>{Objeto.Description}</option>
                    );
                  })}
                  {ListaDocumentoPorPais}
                </select>
              </>}


            </div>
          </div>
          <div class="col-md-4 ">
            <h5 className=" _c-dark"><span className="_c-p">*</span>  Número de documento  <span className=" _c-p"></span></h5>
            <div class="theme-payment-page-form-item form-group">
              {!HabilitarDocumentosReceptor ? (<>
                <input
                  value={PasajeroDocumento}
                  onChange={(ev) => asignarPasajeroDocumento(ev.target.value)}
                  class="form-control"
                  type="text"

                  placeholder="Doc. Receptor"
                />
              </>) : <>
                <input
                  value={PasajeroDocumento}
                  onChange={(ev) => asignarPasajeroDocumento(ev.target.value)}
                  class="form-control"
                  type="text"
                  placeholder="Doc. Receptor"
                />
              </>}

            </div>
          </div>
          <div class="col-md-12  _pt-25">
            <h5 className=" _c-dark"><span className="_c-p">*</span> Dirección del titular de la cuenta</h5>
            <div class="theme-payment-page-form-item form-group text-right">
              <input
                value={DireccionPagador}
                onChange={(ev) => AsignarDireccionPagador(ev.target.value)}
                class="form-control"
                type="text"
                placeholder="Dirección"
              />
              <span >Indispensable indicar la dirección completa indicando: País, estado, departamento, ciudad, avenida, calle, etc.</span>
            </div>
          </div>
          <Fragment>
            {ExisteMoneda ? (
              <>
                <div className="col-md-12  _pt-25">
                  <div className="row">
                    <div class="col-md-6 ">
                      <h5 className=" _c-dark"><span className=" _c-p seleccionable" title="Obligatorio">*</span> Aba/ Routing</h5>
                      <div class="theme-payment-page-form-item form-group">
                        <input
                          onChange={(params) => AsignarAba(params.target.value)}
                          class="form-control"
                          type="text"
                          placeholder="Aba/ Routing"
                          value={Aba}
                        />
                        <span>Aba/ Routing donde desea recibir el reembolso</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h5 className=" _c-dark"><span className=" _c-p seleccionable" title="Obligatorio">*</span> Swift</h5>
                      <div class="theme-payment-page-form-item form-group">
                        <input
                          onChange={(params) => AsignarSwift(params.target.value)}
                          class="form-control"
                          type="text"
                          placeholder="Swift"
                          value={Swift}
                        />
                        <span>Swift donde desea recibir el reembolso </span>
                      </div>
                    </div>

                  </div>
                </div>
              </>
            ) : (<></>)}
          </Fragment>
          <Fragment>
            {EsTitularCuenta ? (
              <>
                {/* DOCUMENTOS DEL TITULAR */}
                <div className="col-md-12 _pt-25">
                  <h5 className=" _c-dark"><span className="_c-p">*</span>  Documentos a consignar <small>En formato *<b>PDF/JPG</b>*</small></h5>
                  <div class="theme-search-results">
                    <div class="theme-search-results-item _mb-10 _b-n theme-search-results-item-rounded theme-search-results-item-">
                      <div class="theme-search-results-item-preview theme-search-results-item-preview2">
                        <a class="theme-search-results-item-mask-link" href="#searchResultsItem-1" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="searchResultsItem-1"></a>
                        <div class="row" data-gutter="20">
                          <div class="col-md-10 ">
                            <span class="theme-account-preferences-item-title">Pagador titular</span>
                          </div>

                          <div class="col-md-2 text-center">
                            <a class=" collapsed" href="#TipoPagador" data-toggle="collapse" aria-expanded="false" aria-controls="TipoPagador">
                              <i class="fa fa-angle-down _c-dark swing-in-top-fwd"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="collapse theme-search-results-item-collapse" id="searchResultsItem-1">
                        <div class="theme-search-results-item-extend">
                          <a class="theme-search-results-item-extend-close" href="#searchResultsItem-1" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="searchResultsItem-1">&#10005;</a>
                          <div class="theme-search-results-item-extend-inner theme-search-results-item-extend-inner2">
                            <div class="theme-search-results-item-flight-detail-items">
                              <div class="theme-search-results-item-flight-details">
                                <div class="row">
                                  <div className="col-md-12">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Cuenta bancaria</h5>
                                    <input
                                      type="file"
                                      accept="image/jpeg,application/pdf"
                                      multiple={false}
                                      onChange={(params) => AsignarCuentaBancaria(params.target.files[0])} />
                                    <small><b>Soporte:</b> Cheque o libreta donde se visualice el número de cuenta y nombre del beneficiario. </small>
                                  </div>
                                  <div className="col-md-12 _pt-20">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Documento de identidad</h5>
                                    <input
                                      type="file"
                                      accept="image/jpeg,application/pdf"
                                      multiple={false}
                                      onChange={(params) => AsignarDocumentoIDentidad(params.target.files[0])} />
                                    <small>Doc. de identidad del pasajero </small>
                                  </div>
                                  <div className="col-md-12 _pt-20">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Declaración jurada</h5>
                                    <input
                                      type="file"
                                      accept="image/jpeg,application/pdf"
                                      multiple={false}
                                      onChange={(params) => ASignarDelcaracionJurada(params.target.files[0])} />
                                    <b> <a className=" hover-Oscuro _c-p" target={'_Blank'} rel="nofollow noopener noreferrer" href={`${process.env.PUBLIC_URL}/Descargas/nuevas/CARTA DE DECLARACIÓN JURADA (V2.0).pdf`}>Descargar formato.</a></b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>) : (
              <>
                {/* DOCUMENTOS NO TITULAR */}
                <div className="col-md-12 _pt-25">
                  <h5 className=" _c-dark"><span className="_c-p">*</span>  Documentos a consignar <small>En formato *<b>PDF/JPG</b>*</small></h5>
                  <div class="theme-search-results">
                    <div class="theme-search-results-item _mb-10 _b-n theme-search-results-item-rounded theme-search-results-item-">
                      <div class="theme-search-results-item-preview theme-search-results-item-preview2">
                        <a class="theme-search-results-item-mask-link" href="#searchResultsItem-1" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="searchResultsItem-1"></a>
                        <div class="row" data-gutter="20">
                          <div class="col-md-10 ">
                            <span class="theme-account-preferences-item-title">Pagador no titular</span>
                          </div>
                          <div class="col-md-2 text-center">
                            <a class=" collapsed" href="#TipoPagador" data-toggle="collapse" aria-expanded="false" aria-controls="TipoPagador">
                              <i class="fa fa-angle-down _c-dark swing-in-top-fwd"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="collapse theme-search-results-item-collapse" id="searchResultsItem-1">
                        <div class="theme-search-results-item-extend">
                          <a class="theme-search-results-item-extend-close" href="#searchResultsItem-1" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="searchResultsItem-1">&#10005;</a>
                          <div class="theme-search-results-item-extend-inner theme-search-results-item-extend-inner2">
                            <div class="theme-search-results-item-flight-detail-items">
                              <div class="theme-search-results-item-flight-details">
                                <div class="row">
                                  <div className="col-md-12">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Doc. Identidad de tercero</h5>
                                    <input
                                      type="file"
                                      accept="image/jpeg,application/pdf"
                                      multiple={false}
                                      onChange={(params) => AsignarIdentidadTercero(params.target.files[0])} />
                                    <small>Documento del pagador</small>
                                  </div>
                                  <div className="col-md-12 _pt-20">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span> Autorización a tercero</h5>
                                    <input
                                      type="file"
                                      accept="image/jpeg,application/pdf"
                                      multiple={false}
                                      onChange={(params) => AsignarAutorizacionTercero(params.target.files[0])} />
                                    <b> <a className=" hover-Oscuro _c-p" target={'_Blank'} rel="nofollow noopener noreferrer" href={`${process.env.PUBLIC_URL}/Descargas/nuevas/CARTA DE AUTORIZACIÓN A TERCERO (V2.0).pdf`}>Descargar formato "autorización de terceros".</a></b>
                                    <br />
                                    <b> <a className=" hover-Oscuro _c-p" target={'_Blank'} rel="nofollow noopener noreferrer" href={`${process.env.PUBLIC_URL}/Descargas/nuevas/CARTA DE AUTORIZACIÓN A TERCERO- EFECTIVO (V1.0).pdf`}>Descargar formato "autorización de terceros en efectivo".</a></b>
                                    <br />
                                    <b> <a className=" hover-Oscuro _c-p" target={'_Blank'} rel="nofollow noopener noreferrer" href={`${process.env.PUBLIC_URL}/Descargas/nuevas/CARTA DE AUTORIZACIÓN A TERCERO- MENOR DE EDAD (V1.0).pdf`}>Descargar formato "autorización de terceros en menores de edad".</a></b>

                                  </div>
                                  <div className="col-md-12 _pt-20">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Cuenta bancaria</h5>
                                    <input
                                      type="file"
                                      accept="image/jpeg,application/pdf"
                                      multiple={false}
                                      onChange={(params) => AsignarCuentaBancaria(params.target.files[0])} />
                                    <small>Cuenta bancaria del tercero</small>
                                  </div>
                                  <div className="col-md-12 _pt-20">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Documento de identidad</h5>
                                    <input
                                      type="file"
                                      accept="image/jpeg,application/pdf"
                                      multiple={false}
                                      onChange={(params) => AsignarDocumentoIDentidad2(params.target.files[0])} />
                                    <small>Doc. de identidad del pasajero </small>
                                  </div>
                                  <div className="col-md-12 _pt-20">
                                    <h5 className=" _c-dark"><span className="_c-p">*</span>  Declaración jurada</h5>
                                    <input
                                      type="file"
                                      accept="image/jpeg,application/pdf"
                                      multiple={false}
                                      onChange={(params) => ASignarDelcaracionJurada(params.target.files[0])} />
                                    <b> <a className=" hover-Oscuro _c-p" target={'_Blank'} rel="nofollow noopener noreferrer" href={`${process.env.PUBLIC_URL}/Descargas/nuevas/CARTA DE DECLARACIÓN JURADA (V2.0).pdf`}>Descargar formato.</a></b>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>

            )
            }
          </Fragment>
        </div>
        <div className="row _pt-50">
          <div className="col-md-12 text-center">
            <a onClick={() => ProcesarReembolso()} class=" seleccionable theme-search-area-submit _mt-0 theme-search-area-submit-no-border theme-search-area-submit-no-border2 theme-search-area-submit-curved theme-search-area-submit-primary">Realizar solicitud</a>
          </div>
        </div>

      </div>
    </>
  );
}

export default Paso4;