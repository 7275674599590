import React, { useState, useEffect, Fragment } from 'react';
import MensajeAdvertencia from './MensajeAdvertencia';
import { ReembolsoSevices } from "../../Services/";
import LoaderScreen from '../layout/LoaderScreen';
const Paso3 = (props) => {
  const [ActivarLoader, setActivarLoader] = useState(false);
  const [MensajeLoader, setMensajeLoader] = useState("");
  const [caracteresRestantes, setcaracteresRestantes] = useState(300);
  const [Motivo, setMotivo] = useState("");
  const [Observaciones, setObservaciones] = useState("");
  const [ErrorMotivoMsg, setErrorMotivoMsg] = useState("");
  const [Motivos, setMotivos] = useState([]);
  var ListaMotivos = [];


  function RestarCaracteres(params) {


    var resultado = 300 - params.length;
    setcaracteresRestantes(resultado);

  }

  function asignarMotivo(params) {
    if (params != "") {
      //LOCALSTORAGE
      window.localStorage.setItem("Motivo", params);
      setErrorMotivoMsg("");
      setMotivo(params);
      CamposObligatoriosCorrectos();
    }
    else {
      setErrorMotivoMsg("Campo es obligatorio");
      window.localStorage.removeItem("Motivo");
      CamposObligatoriosCorrectos();
    }
  }

  function asignarObservacion(params) {
    if (params != "") {
      //LOCALSTORAGE
      window.localStorage.setItem("Observaciones", params);
      setErrorMotivoMsg("");
      setObservaciones(params);
      CamposObligatoriosCorrectos();
    }
    else {
      setObservaciones(params);
      window.localStorage.removeItem("Observaciones");
      CamposObligatoriosCorrectos();
    }
  }

  async function MObtenerMotivos() {

    var PoseeBoletos = window.localStorage.getItem("PoseeBoleto");
    setMensajeLoader("Obteniendo motivos...");
    setActivarLoader(true);
    var Boleto;
    if (PoseeBoletos == "S") {
      Boleto = 0;
    }
    else {
      Boleto = 1;
    }
    const peticion = await ReembolsoSevices.ObtenerMotivos(Boleto);
    if (peticion.status == 200) {
      if (peticion.resp != null) {
        setMotivos(peticion.resp);
        setActivarLoader(false)
      }
    }
    else {
      setMotivo("Ocurrió un error al cargar las monedas.");
    }
  }

  useEffect(() => {
    window.localStorage.setItem("Paso3", btoa(false));
    MObtenerMotivos();
  }, []);

  //VALIDAR SI LOS CAMPOS OBLIGATORIOS ESTAN CORRECTOS
  function CamposObligatoriosCorrectos() {
    var Motivo = window.localStorage.getItem("Motivo");
    if (Motivo != null) {
      window.localStorage.setItem("Paso3", btoa(true));
    }
    else {
      window.localStorage.setItem("Paso3", btoa(false));
    }
  }

  return (
    <>
      <LoaderScreen props={ActivarLoader} mensaje={MensajeLoader} />
      <div className="_pt-30">
        <div class="theme-page-section-header _ta-l">
          <h5 class="theme-page-section-title">Motivo del reembolso</h5>
          <h5 className="feature-subtitle _op-06">Todos los campos habilitados y marcados con asteriscos (<b>*</b>) son obligatorios.</h5>
          <hr className=" style-3"></hr>
        </div>
        <div class="row row-col-gap " data-gutter="20">

          <div class="col-md-12 ">
            <h5 className=" _c-dark"><span className="_c-p">*</span>  Motivo <span className=" _c-p"> {ErrorMotivoMsg}</span></h5>
            <div class="theme-payment-page-form-item form-group">
              <i class="fa fa-angle-down"></i>
              <select class="form-control seleccionable"
                onChange={(ev) => asignarMotivo(ev.target.value)}
                value={Motivo}
              >
                <option value={""}>Seleccione</option>
                {Object.keys(Motivos).forEach(valor => {
                  var Objeto = Motivos[valor];
                  ListaMotivos.push(
                    <option value={Objeto.ID}>{Objeto.Description}</option>
                  );
                })}
                {ListaMotivos}
              </select>

            </div>
          </div>
          <div class="col-md-12 ">
            <h5 className="_c-dark">Observaciones o comentarios</h5>
            <div class="theme-payment-page-form-item form-group">
              <textarea
                value={Observaciones}
                className=" form-control _w-100pct"
                maxLength="300"
                value={Observaciones}
                onChange={(ad) => { asignarObservacion(ad.target.value) }}
                onKeyUp={(ad) => { RestarCaracteres(ad.target.value) }}
              >
              </textarea>
              <span className=" _c-dark">Máximo 300 caracteres - (<b>{caracteresRestantes}</b> cacaracteres restantes)</span>
            </div>
          </div>
          <MensajeAdvertencia />
        </div>

      </div>
    </>
  );
}

export default Paso3;