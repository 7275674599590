import urls from "./Url";

// -----------------
//FUNCION PARA OBTENER PAISES 
// METODO: GET
// -----------------
const ObtenerPaises = async () => {
    const peticion = await fetch(urls.Paises, {
        headers: {
            "Content-Type": "application/json",
            // "Authorization": 'Basic ' + btoa('avior:fazQMUyq'),
        },
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};

const ObtenerDocumentosPorPaises = async (CodCountry) => {
    const peticion = await fetch(`${urls.DocumentTypes}?CodCountry=${CodCountry}`, {
        headers: {
            "Content-Type": "application/json",
            // "Authorization": 'Basic ' + btoa('avior:fazQMUyq'),
        },
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};
// -----------------
//FUNCION PARA OBTENER MONEDAS 
// METODO: GET
// -----------------
const ObtenerMonedas = async () => {
    const peticion = await fetch(urls.Monedas, {
        headers: {
            "Content-Type": "application/json",
            // "Authorization": 'Basic ' + btoa('avior:fazQMUyq'),
        },
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};

// -----------------
//FUNCION PARA OBTENER ORIGENES 
// METODO: GET
// -----------------
const ObtenerOrigenes = async () => {
    const peticion = await fetch(urls.Origen, {
        headers: {
            "Content-Type": "application/json",
            // "Authorization": 'Basic ' + btoa('avior:fazQMUyq'),
        },
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};

// -----------------
//FUNCION PARA OBTENER TIPO DE PASAJERO 
// METODO: GET
// -----------------
const ObtenerTipoPasajero = async () => {
    const peticion = await fetch(urls.TipoPasajero, {
        headers: {
            "Content-Type": "application/json",
            // "Authorization": 'Basic ' + btoa('avior:fazQMUyq'),
        },
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};


// -----------------
//FUNCION PARA OBTENER EL DESTINO SEGUN UN ORIGEN
// METODO: GET
// -----------------
const ObtenerDestino = async (Origen) => {
    const peticion = await fetch(`${urls.Destinos}?codOrigin=${Origen}`, {
        headers: {
            "Content-Type": "application/json",
            // "Authorization": 'Basic ' + btoa('avior:fazQMUyq'),
        },
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};

// -----------------
//FUNCION PARA OBTENER LISTA DE MOTIVOS
// METODO: GET
// -----------------
const ObtenerMotivos = async (tipoRefund) => {
    const peticion = await fetch(`${urls.Motivos}?tipoRefund=${tipoRefund}`, {
        headers: {
            "Content-Type": "application/json",
            // "Authorization": 'Basic ' + btoa('avior:fazQMUyq'),
        },
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};


// -----------------
//FUNCION PARA OBTENER LISTADO DE BANCOS
// METODO: GET
// -----------------
const ObtenerBancos = async () => {
    const peticion = await fetch(`${urls.Bancos}`, {
        headers: {
            "Content-Type": "application/json",
            // "Authorization": 'Basic ' + btoa('avior:fazQMUyq'),
        },
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};


// -----------------
//FUNCION PARA OBTENER LISTADO DE CENTRO DE SERVICIOS
// METODO: GET
// -----------------
const ObtenerCentroDeServicios = async () => {
    const peticion = await fetch(`${urls.CentrosDeServicios}`, {
        headers: {
            "Content-Type": "application/json",
            // "Authorization": 'Basic ' + btoa('avior:fazQMUyq'),
        },
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};

// -----------------
//FUNCION PARA OBTENER TICKET DE PASAJERO
// METODO: GET
// -----------------
const ObtenerTiketPasajero = async () => {
    const peticion = await fetch(`${urls.CentrosDeServicios}`, {
        headers: {
            "Content-Type": "application/json",
            // "Authorization": 'Basic ' + btoa('avior:fazQMUyq'),
        },
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};


// -----------------
//FUNCION PARA VALIDAR BOLETO
// METODO: POST
// -----------------
const ValidarBoleto = async (numTicket, datePurchase, isComplaint) => {
    const peticion = await fetch(urls.ChequearTicket, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            //   "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
        },
        body: JSON.stringify({
            numTicket,
            datePurchase,
            isComplaint
        })
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};


// -----------------
//FUNCION PARA PROCESAR REEMBOLSO
// METODO: POST
// -----------------
const ProcesarReembolso = async (request) => {
    const peticion = await fetch(urls.ProcesarReembolso, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            //   "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
        },
        body: request
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};

const Recapchav = async (request,token) => {
    const peticion = await fetch(`${urls.Recapcha}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            //   "Authorization":'Basic '+ btoa('avior:fazQMUyq'),
        },
        body: request
    });
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};

// -----------------
//FUNCION PARA OBTENER LOS METODOS DE PAGO
// METODO: GET
// -----------------
const ObtenerFormaPago = async (CurrencyID, typeRefund) => {
    const peticion = await fetch(`${urls.MetodoPago}?CurrencyID=${CurrencyID}&typeRefund=${typeRefund}`, {
        headers: {
            "Content-Type": "application/json",
        },
    });

    console.log(peticion);
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};


// -----------------
//FUNCION PARA OBTENER ESTATUS DE REEMBOLSO
// METODO: GET
// -----------------
const ObtenerEstatusReembolso = async (SolicitudID, FechaObtenida) => {
    const peticion = await fetch(`${urls.ChequearReembolso}?SolicitudID=${SolicitudID}&FechaObtenida=${FechaObtenida}`, {
        headers: {
            "Content-Type": "application/json",
        },
    });

    console.log(peticion);
    const resp = await peticion.json();
    return {
        status: peticion.status,
        resp
    };
};

const ReembolsoSevices = Object.freeze({
    ObtenerPaises,
    ObtenerMonedas,
    ObtenerOrigenes,
    ObtenerTipoPasajero,
    ValidarBoleto,
    ObtenerFormaPago,
    ObtenerMotivos,
    ObtenerDestino,
    ProcesarReembolso,
    ObtenerEstatusReembolso,Recapchav,
    ObtenerDocumentosPorPaises
});
export default ReembolsoSevices;