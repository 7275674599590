import React, {  useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import 'react-step-progress/dist/index.css';
import MensajeAdvertencia from '../../Services/MensajeAdvertencia';

const LoaderScreen = ({props, mensaje}) => {
  
 
    return (
        <>
            <LoadingOverlay active={props} spinner text={mensaje} >
               
            </LoadingOverlay>
        </>
    );
}
export default LoaderScreen;