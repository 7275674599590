import React from 'react';
import Indice from '../Formulario/indice';
import Footer from './footer';
import Menu2 from './menu2';
import ImagenFondo from './../Consulta/Header-beneficios.jpg';
const LayoutInicio = ({ props, mensaje }) => {


  return (
    <>
      <Menu2 />
        <div className="theme-hero-area _pt-50 _pb-200" >
          <div className="theme-hero-area-bg-wrap">
            <div className="theme-hero-area-bg" style={{ backgroundImage: `url(${ImagenFondo})` }}></div>
            <div className="theme-hero-area-inner-shadow theme-hero-area-inner-shadow-light"></div>
          </div>
          <div className="theme-hero-area-body">
            <div className="container">
              <div className="theme-page-section _p-0">
                <div className="row">
                  <div className="col-md-10 col-md-offset-1">
                    <div className="theme-mobile-app">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="theme-mobile-app-section">
                            <div className="theme-mobile-app-body">

                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="theme-mobile-app-section"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="theme-item-page-header-body">
                <Indice />
              </div>
            </div>
          </div>
        </div>
   
      <Footer />
    </>
  );
}
export default LayoutInicio;