//const baseURL = 'https://localhost:44319/api/';
const baseURL = 'https://api-reembolso.avior.com.ve/api/';
const urls = {
    Paises: `${baseURL}Countries`, //OBTENER LISTA DE PAISES
    Origen: `${baseURL}Origins`, //ORIGENES
    Destinos:  `${baseURL}Destinations`, //DESTINOS
    Motivos:  `${baseURL}Reasons`,
    TipoDocumentoPorRegion:  `${baseURL}GetDocumentsTypes_xCountry`, // OBTENER LOS DOCUMENTOS SEGUN LA UBICACION DEL USUARUO,
    CentrosDeServicios:  `${baseURL}GetServiceCenter`, // OBTENER CENTROS DE SERVICIOS
    CentrosDeServiciosPorRegion:  `${baseURL}GetServiceCenter_xCountry`,// OBTENER CENTROS DE SERVICIOS POR UBICACION
    TiketPasajero: `${baseURL}GetPAXTicket`, //Tiket de pasajero
    TiketPasajeroPorTipo: `${baseURL}GetTypePAXTicket`, //Tiket de pasajero or tipo
    Bancos: `${baseURL}GetBanks`, // OBTENER LISTA DE BANCOS
    SoportesAConsignar: `${baseURL}GetSupports`, // OBTENER SOPORTES A CONSIGNAR
    CodigoValidacionReembolso: `${baseURL}CheckValidationCode`, // OBTENER CODIGO DE VALIDACION DE REEMBOLSO
    DocumentosAConsignar: `${baseURL}GetDocumentsToConsigned`,
    TipoPasajero: `${baseURL}TypePAXs`, //TIPO DE PASAJEROS
    Monedas: `${baseURL}Curriencies`, //MONEDAS
    ChequearTicket: `${baseURL}CheckTicket`, //CHEQUEAR TIQUET
    MetodoPago: `${baseURL}PaymentMethods`, //METODOS DE PAGO
    ProcesarReembolso: `${baseURL}RegisterRefund`, //METODOS DE PAGO
    ChequearReembolso: `${baseURL}CheckTicket`, //METODOS DE PAGO
    Recapcha: `${baseURL}Recapchav`, //METODOS DE PAGO,
    DocumentTypes: `${baseURL}DocumentTypes`, //METODOS DE PAGO
}

export default urls

