import React, { useState, useEffect, Fragment } from 'react';

const Footer = (props) => {
    return (
        <>
        <div className="theme-copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <a href="https://www.avior.com.ve/"  rel="noopener noreferrer nofollow"> <img src={`${process.env.PUBLIC_URL}/img/logo.png`} className="text-center _w-33pct" /></a>
                    </div>
                    <div className="col-md-6">
                        <ul className="theme-copyright-social ">
                            <li>
                                <span className="white text-center">{(new Date().getFullYear())} - Todos los derechos reservados. </span>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default Footer;