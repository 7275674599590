import React, { useState, useEffect, Fragment } from 'react';
import ReactToPdf from 'react-to-pdf';
import Moment from 'moment';
const ResultadoSolicitud = () => {
  const ref = React.createRef();
  const [Resultado, setResultado] = useState("");
  const [ResulSb, setResulSb] = useState("");
  function VolverAlInicio() {
    window.localStorage.clear();
    window.location.href = "https://avior.com.ve";
  }
  
  var NroReembolso = window.localStorage.getItem("NroReembolso");
  var hoy = new Date();
  useEffect(() => {
    var Resul = window.localStorage.getItem("Resultado");
    var ResulSb = window.localStorage.getItem("ResultadoSb");
    setResultado(Resul);
    setResulSb(ResulSb);
 
    document.getElementsByClassName('_3uApM ')[0].style.display = 'none';
  }, []);
  return (
    <>
      <div className="_pb-90 _pt-30">
        <div class="row">
          <div className=" col-md-4"></div>
          <div class="col-md-4">
            <div class="theme-payment-success">
              <div class="theme-payment-success-header">
                <i class="fa fa-check-circle theme-payment-success-header-icon"></i>
                <h1 class="theme-payment-success-title">{Resultado}</h1>
                <p class="theme-payment-success-subtitle">{ResulSb}</p>
              </div>
              <div ref={ref}>
                <div class="theme-payment-success-box">
                  <ul class="theme-payment-success-summary">
                    <li>N° de solicitud
  <span>#{NroReembolso}</span>
                    </li>
                    <li>Fecha de emisión
  <span>{Moment(hoy).format("DD/MM/YYYY")}</span>
                    </li>
                  </ul>
                  <p class="theme-payment-success-check-order text-center">Pronto estaremos comunicándonos con usted.

              </p>
                </div>
                <hr className=" style2" />
              </div>
              <ul class="theme-payment-success-actions hover-Oscuro">

                <li>

                  <a className="seleccionable hover-Oscuro" onClick={(es) => VolverAlInicio()}>
                    <i class="fa fa-home"></i>
                    <p>Ir a
                      <br />
                      Inicio
                       </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className=" col-md-4"></div>
        </div>
      </div>

    </>
  );
}

export default ResultadoSolicitud;