import React, { useState, useEffect, Fragment } from 'react';


const Menu2 = (props) => {

    return (
        <>



          <nav className="navbar navbar-default navbar-primary navbar-theme navbar-theme-full  navbar-theme-border btn-shadow2"  >
                <div className="container">
                    <div className="navbar-inner nav container">
                        <div className="navbar-header">
                            <button className="navbar-toggle collapsed" data-target="#navbar-main" data-toggle="collapse" type="button" area-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <a href="https://www.avior.com.ve/" rel="noopener noreferrer nofollow"  >
                                <img src={`${process.env.PUBLIC_URL}/img/logo.png`} className="_pt-15 _pl-15  _w-40pct" />
                            </a>
                        </div>
                        <div className="collapse navbar-collapse" id="navbar-main">
                            <ul className="nav navbar-nav"></ul>
                            <ul className="nav navbar-nav navbar-right">

                                <li className="navbar-nav-item-user">
                                    <a title="Inicio" href="https://www.avior.com.ve/"  rel="noopener noreferrer nofollow" >
                                        Inicio
                                </a>
                                </li>
                                <li className="navbar-nav-item-user">
                                    <a title="Inicio" href="https://www.avior.com.ve/destinos"  rel="noopener noreferrer nofollow" >
                                        Destinos
                                </a>
                                </li>
                                <li className="navbar-nav-item-user">
                                    <a title="Inicio" href="/"  rel="noopener noreferrer nofollow" >
                                        Consultar
                                </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav> 
        </>
    );
}

export default Menu2;